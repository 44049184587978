import * as qs from 'qs'
import { API_ENDPOINT } from 'src/settings/global'

export const getQueryValue = (key, defaultValue = null) => {
    const locationParams = new URLSearchParams(window.location.search)
    const keyValue = locationParams.get(key)
    return keyValue !== null ? keyValue : defaultValue
}

export const getQueryLocale = () => {
    return getQueryValue('locale', 'fr')
}

export const getQueryDefaultParams = () => {
    const defaultParams = { locale: getQueryLocale() }
    for (const field of [
        'ignore_missing',
        'ignore_publish',
        'experience_id',
        'demo_for',
        'dev_mode',
        'look_width',
        'use_accessory_mask',
        'allow_archived',
    ]) {
        const fieldValue = getQueryValue(field)
        if (fieldValue !== null) {
            defaultParams[field] = fieldValue
        }
    }
    return defaultParams
}

export const getQueryGender = (companyProfileGenders: string[]) => {
    const queryValue = getQueryValue('gender')
    if (!queryValue) {
        if (!companyProfileGenders) {
            return null
        }

        if (companyProfileGenders.length === 1) {
            return companyProfileGenders[0]
        }

        if (companyProfileGenders.find((profile) => profile === 'FEMALE')) {
            return 'FEMALE'
        }

        return null
    }

    if (!companyProfileGenders) {
        return null
    }

    return companyProfileGenders.find((profile) => profile === queryValue)
}

export const queryParamSerializer = (param) => {
    return qs.stringify(param, { arrayFormat: 'brackets', encode: false })
}

const apiPrefix: string = getQueryValue('api_prefix', '/v1/marinehenrion')

export const baseUrl = () =>
    (API_ENDPOINT || `https://api.veesual.ai`) + (!apiPrefix.match(/^\//) ? '/' : '') + apiPrefix

export const updateSearchString = (updateObject: { [key: string]: string }) => {
    const searchObject = qs.parse(window.location.search.replace('?', ''))

    Object.keys(updateObject).forEach((keyString) => {
        if (updateObject[keyString] === null) {
            delete searchObject[keyString]
            return
        }
        searchObject[keyString] = updateObject[keyString]
    })

    return qs.stringify(searchObject)
}
