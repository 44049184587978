import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import ImageSmooth from 'src/components/image/ImageSmooth'

import FilterSelect from 'src/components/select/filter'

import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart, getGarmentSizeLabel } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'
import useCustomHistory from 'src/utils/custom-history-hook'
import { getFavoriteIcon } from 'src/utils/icon'
import { isGarmentInFavorites } from 'src/utils/favorite'
import useShowModal from 'src/utils/showModal'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { HandleLookRequest } from 'src/store/actions/look'
import { Button, Card, Typography } from 'antd'

const { Title, Paragraph } = Typography

interface CardItemProps {
    data: Models.Garment
    favorites: Models.Garment[]
    addOnFavorites(value: Models.Garment): void
    removeFromFavorites(item: Models.Garment): void
    ratio: number
}

const CardItem: React.FunctionComponent<CardItemProps> = (props) => {
    const dispatch = useDispatch()
    const customHistory = useCustomHistory()
    const { t } = useCustomTranslation()
    const showModal = useShowModal()
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const descriptionCatalog = useSelector(
        (state: State.Root) => state.profile?.company?.description_catalog
    )
    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)
    const sizeSelectRef = useRef(null)

    const [currentSize, setCurrentSize] = useState(null)
    const [changingSize, setChangingSize] = useState(false)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)

    const { data, favorites, addOnFavorites, removeFromFavorites, ratio } = props
    const inFavorites = isGarmentInFavorites(favorites, data)
    const optionSize = getGarmentOptionSizes(data)

    const garment = useSelector((state: State.Root) =>
        state.look.request ? state.look.request[data.garment_type.toLowerCase()] : null
    )

    const isPromotion = useMemo(() => {
        if (data) {
            return !!data.product_price_original && data.product_price_original > data.product_price
        }

        return false
    }, [data])

    const isSelected = garment?.garment_id === data?.garment_id

    const imageUrl =
        useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url

    useEffect(() => {
        setChangingSize(false)
        setGoToCart(false)
    }, [isSelected])

    const handleCardClick = () => {
        trackEvent('Item Selected', data, 'Catalog')
        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [data.garment_type.toLowerCase()]: data,
                },
                keepAdditional: true,
                focus: data.garment_type,
            })
        )
        dispatch(ChangeShowFiltersAction(false))

        document.getElementById('layoutRightContentId').scrollTo({ top: 1, behavior: 'smooth' })
    }

    const handleSeeProduct = (e) => {
        e.stopPropagation()
        trackEvent('Item Detailed', data, 'Catalog')
        customHistory.push('/product')
    }

    const handleChooseSize = (e) => {
        e.stopPropagation()
        trackEvent('Choose size Clicked', data, 'Catalog')
        setChangingSize(true)
    }

    const handleSizeChange = (value) => {
        if (value) {
            trackEvent(
                'Size Selected',
                [
                    data,
                    {
                        item_size_selected: value,
                        item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                    },
                ],
                'Catalog'
            )
        }
        setCurrentSize(value)
        setGoToCart(false)
    }

    const handleAddToCart = (e) => {
        e.stopPropagation()
        if (!currentSize) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                data,
                {
                    item_size_selected: currentSize,
                    item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                },
            ],
            'Catalog'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(data, currentSize, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        data,
                        {
                            item_size_selected: currentSize,
                            item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                        },
                    ],
                    'Catalog'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                showModal(garment?.garment_id, garment, 'Catalog')
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [data], 'Catalog')
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Item Saved to favorite', data, 'Catalog')
        addOnFavorites(data)
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Item Removed from favorite', data, 'Catalog')
        removeFromFavorites(data)
    }

    const handleSeeLook = (e) => {
        e.stopPropagation()
        trackEvent('See the look Clicked', data, 'Catalog')
        customHistory.push('/swipe')
    }

    return (
        <Card
            className={
                'card card--container override_card_container override_card_catalog_container' +
                (data.product_brand ? ' card--with-brand' : '') +
                (descriptionCatalog === false ? ' card--without-description' : '')
            }
            hoverable
            onClick={handleCardClick}
            cover={
                <div className='card card--image-container'>
                    <div
                        className={
                            'override_card_cover_catalog ' +
                            (isSelected ? 'card--image card--blur' : 'card--image')
                        }
                    >
                        <ImageSmooth
                            className='card--background'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                        />
                    </div>
                    {isSelected && (
                        <div className='card--image-blur'>
                            <div className='card--actions'>
                                <Button
                                    onClick={handleSeeLook}
                                    ghost
                                    className={`button card--light action--see-look ${
                                        changingSize ? 'action--changing-size' : ''
                                    }`}
                                >
                                    {t('product.see_look')}
                                </Button>
                                <Button
                                    onClick={handleSeeProduct}
                                    ghost
                                    className={`button card--light action--see-product ${
                                        changingSize ? 'action--changing-size' : ''
                                    }`}
                                >
                                    {t('product.see_product')}
                                </Button>
                                {changingSize ? (
                                    <>
                                        <FilterSelect
                                            ref={sizeSelectRef}
                                            name='size'
                                            onChange={handleSizeChange}
                                            placeholder={t('product.size')}
                                            value={currentSize}
                                            options={optionSize}
                                            translation={false}
                                            defaultOpen={true}
                                        />
                                        {goToCart && cartUrl ? (
                                            <Button
                                                className='button--card-main card--add-to-cart card--main'
                                                onClick={handleGoToCart}
                                                type='primary'
                                                href={cartUrl}
                                                target={
                                                    company.link_target_parent
                                                        ? '_parent'
                                                        : '_blank'
                                                }
                                                key='goToCart'
                                            >
                                                {t('product.go_to_cart')}
                                            </Button>
                                        ) : (
                                            <Button
                                                className='button card--main card--add-to-cart'
                                                loading={addingToCart}
                                                onClick={handleAddToCart}
                                                type='primary'
                                            >
                                                {t('product.add_to_cart')}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        className='button card--main card--add-to-cart'
                                        onClick={handleChooseSize}
                                        type='primary'
                                    >
                                        {t('product.add_to_cart')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            }
        >
            {data.product_brand && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--text override_card_brand'
                >
                    {parse(data.product_brand)}
                </Paragraph>
            )}
            <Title
                ellipsis={{
                    rows: descriptionCatalog === false ? 2 : 1,
                }}
                className='title title--h3 title--center card--title override_card_title'
            >
                {parse(data.product_name)}
            </Title>
            {descriptionCatalog !== false && (
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--center text--small card--text override_card_description'
                >
                    {parse(data.product_description)}
                </Paragraph>
            )}
            {isPromotion && (
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--center card--price--promotion'
                >
                    <span className='card--price--original'>
                        {formattedPrice(
                            data.product_price_original,
                            data.product_currency,
                            priceFloat
                        )}
                    </span>
                    <span className='card--price--percent'>
                        -
                        {Math.round(
                            ((data.product_price_original - data.product_price) * 100) /
                                data.product_price_original
                        )}
                        %
                    </span>
                </Title>
            )}
            <Title
                ellipsis={{
                    rows: 1,
                }}
                className={`title title--center title--h2 card--title card--price--final${
                    isPromotion ? ' card--price--final--promotion' : ''
                }`}
            >
                {formattedPrice(data.product_price, data.product_currency, priceFloat)}
            </Title>
            {!inFavorites ? (
                <Button
                    onClick={handleAddToFavorite}
                    icon={getFavoriteIcon('outlined')}
                    type='link'
                    className='button card--favorite'
                ></Button>
            ) : (
                <Button
                    icon={getFavoriteIcon('filled')}
                    type='link'
                    className='button card--favorite'
                    onClick={handleRemoveFromFavorite}
                ></Button>
            )}
        </Card>
    )
}

export default CardItem
