import { Badge, Button } from 'antd'
import React, { MouseEvent } from 'react'
import { getFilterIcon } from 'src/utils/icon'

interface FilterButtonProps {
    withGarmentFilters?: boolean
    onClick?: (e: MouseEvent) => void
    countFilter: number
    showOverride?: boolean
    classNameOverride?: string
    buttonClassNameOverride?: string
}

export default function FilterButton(props: FilterButtonProps) {
    const {
        withGarmentFilters,
        onClick,
        countFilter,
        showOverride,
        classNameOverride,
        buttonClassNameOverride,
    } = props

    return (
        <div
            style={
                showOverride ||
                (withGarmentFilters &&
                    (location.pathname === '/catalog' ||
                        (location.pathname === '/swipe' && window.innerWidth >= 768)))
                    ? null
                    : {
                          opacity: 0,
                          pointerEvents: 'none',
                      }
            }
            className={`${classNameOverride ? classNameOverride : 'button--filter'}`}
            onClick={(e) => onClick && onClick(e)}
        >
            <Badge count={countFilter} offset={[-5, 10]} color='white' size='small'>
                <Button
                    icon={getFilterIcon()}
                    type='link'
                    className={`button ${
                        buttonClassNameOverride
                            ? buttonClassNameOverride
                            : 'layout--header-filter-button'
                    }`}
                />
            </Badge>
        </div>
    )
}
