import React, { useMemo } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useAppSelector } from 'src/store'
import { useLocation } from 'react-router-dom'
import ExperienceSelect from '../select/ExperienceSelect'
import ExitButton from '../button/ExitButton'
import { Button, Tabs } from 'antd'
import { trackEvent } from 'src/utils/tracking'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import useTypeClick from 'src/utils/typeClick-hook'
import useCustomHistory from 'src/utils/custom-history-hook'
import { useDispatch } from 'react-redux'
import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'
import TypeTabs from './TypeTabs'
import FilterButton from '../button/FilterButton'
import { countActiveFilters } from 'src/store/slices/databaseSlice'
import useCustomTranslation from 'src/utils/translation'
import SubHeader from './SubHeader'
import { useFiltersContext } from '../LocalFiltersContext'
import HeaderButtons from '../button/HeaderButtons'

interface HeaderMainContentProps {
    onBackClick: () => void
    toggleDrawer: (open: boolean) => void
    showSubHeader?: boolean
    withGarmentFilters?: boolean
}

export default function HeaderMainContent(props: HeaderMainContentProps) {
    const { t } = useCustomTranslation()
    const location = useLocation()
    const { typeClick } = useTypeClick()
    const customHistory = useCustomHistory()
    const FiltersContext = useFiltersContext()
    const dispatch = useDispatch()

    const company = useAppSelector((state) => state.profile?.company)
    const numberActiveFilters = useAppSelector((state) => countActiveFilters(state))
    const favorites = useAppSelector((state) => state.favorites)
    const garmentType = useAppSelector((state) => state.garment.type)
    const showFilters = useAppSelector((state) => state.filters.showFilters)

    const { toggleDrawer, onBackClick, showSubHeader, withGarmentFilters } = props

    const handleTypeClick = (value: string) => {
        trackEvent('Type Clicked', { catalog_type: value }, 'Menu')
        typeClick(value)
    }

    const handleFavoritesClick = (e) => {
        e.preventDefault()
        trackEvent(
            'Favorite Clicked',
            { favorite_count: favorites?.outfits?.length + favorites?.items?.length },
            'Menu'
        )
        toggleDrawer(false)
        if (location.pathname !== '/favorites') {
            return customHistory.push('/favorites')
        }
    }

    const handleFavoritesList = (value: any) => {
        trackEvent(
            'Favorite type Clicked',
            {
                favorite_type: value == '1' ? 'outfit' : 'item',
                favorite_count:
                    value == '1' ? favorites?.outfits?.length : favorites?.items?.length,
            },
            'Menu'
        )
        dispatch(SwitchFavoritesContentAction(value === '1'))
    }

    const handleFilterClick = (e, path = null) => {
        e.preventDefault()
        if (path === '/model') {
            trackEvent('Filter Clicked', { filter_type: 'model' }, 'Menu')
        } else {
            trackEvent('Filter Clicked', { catalog_type: garmentType, filter_type: 'item' }, 'Menu')
        }
        dispatch(ChangeShowFiltersAction(!showFilters))
    }

    const showTabs = useMemo(
        () =>
            (location.pathname === '/swipe' && window.innerWidth >= 768) ||
            location.pathname === '/catalog',
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, company, window.innerWidth]
    )

    return (
        <div
            className={`layout--header-main-container ${
                company.garment_category_facets ? 'layout--header-title-separator' : ''
            }`}
        >
            <div className='layout--header-top-container'>
                <div className='layout--header-left'>
                    <div className='layout--header-left-content'>
                        <div className='layout--header-left-content-menu'>
                            {showTabs && (
                                <TypeTabs onTabClick={handleTypeClick} activeType={garmentType} />
                            )}
                            {location.pathname === '/favorites' && (
                                <Tabs
                                    activeKey={favorites.isOutfit ? '1' : '2'}
                                    onTabClick={handleFavoritesList}
                                    className={`menu menu--container`}
                                    items={[
                                        {
                                            label: `${t('favorite.saved_outfits')} (${
                                                favorites?.outfits?.length
                                            })`,
                                            key: '1',
                                        },
                                        {
                                            label: `${t('favorite.saved_items')} (${
                                                favorites?.items?.length
                                            })`,
                                            key: '2',
                                        },
                                    ]}
                                />
                            )}
                            {!showTabs &&
                                location.pathname === '/model' &&
                                window.innerWidth < 768 && (
                                    <h1 className={`title title--h1 title--model`}>
                                        {t('layout.see_the_look')}
                                    </h1>
                                )}

                            {company.garment_filters ? (
                                <>
                                    {window.innerWidth < 768 ? (
                                        <>
                                            {location.pathname !== '/swipe' && (
                                                <FilterButton
                                                    withGarmentFilters={withGarmentFilters}
                                                    onClick={(e) =>
                                                        handleFilterClick(e, '/catalog')
                                                    }
                                                    countFilter={numberActiveFilters}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <FilterButton
                                            withGarmentFilters={withGarmentFilters}
                                            onClick={(e) => handleFilterClick(e, '/catalog')}
                                            countFilter={numberActiveFilters}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {
                                        // FIX so the title is centered in chose model when no filter on client
                                        window.innerWidth < 768 &&
                                            location.pathname === '/model' && (
                                                <div className='button--filter'>
                                                    <div className='layout--header-filter-button'></div>
                                                </div>
                                            )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className='layout--header-right'>
                    <div className='button--mobile'>
                        {location.pathname === '/swipe' ? (
                            <ExitButton />
                        ) : (
                            <Button
                                onClick={onBackClick}
                                icon={<ArrowLeftOutlined />}
                                type='link'
                                className='button layout--header-burger-button'
                            />
                        )}
                    </div>

                    {company.enable_switch_experiences === true && (
                        <div className='select--experience-container'>
                            <ExperienceSelect />
                        </div>
                    )}
                    <HeaderButtons
                        containerClassName={
                            window.innerWidth < 768
                                ? 'layout--header-button-container-mobile'
                                : undefined
                        }
                        onFavoritesClick={handleFavoritesClick}
                    />
                </div>
            </div>
            {showTabs && showSubHeader && FiltersContext && (
                <SubHeader
                    localFilters={FiltersContext.localFilters}
                    onClick={FiltersContext.onSubHeaderClick}
                    onlyOneSubCategory={FiltersContext.onlyOneSubCategory}
                />
            )}
        </div>
    )
}
