import React, { MouseEvent } from 'react'
import { useAppSelector } from 'src/store'
import { getFavoriteIcon } from 'src/utils/icon'
import useCustomTranslation from 'src/utils/translation'
import ToggleViewButtons from './ToggleViewButtons'
import { Button, Row } from 'antd'

interface HeaderButtonsProps {
    containerClassName?: string
    onFavoritesClick: (e: MouseEvent) => void
}

export default function HeaderButtons(props: HeaderButtonsProps) {
    const { t } = useCustomTranslation()
    const company = useAppSelector((state) => state.profile.company)
    const favorites = useAppSelector((state) => state.favorites)

    const { containerClassName, onFavoritesClick } = props

    return (
        <div
            className={`${
                containerClassName ? containerClassName : 'layout--header-button-container'
            }`}
        >
            {company?.enable_swipe && (
                <Row className='button--mobile' wrap={false}>
                    <ToggleViewButtons />
                </Row>
            )}
            <Button
                icon={
                    favorites.outfits.length || favorites.items.length
                        ? getFavoriteIcon('filled')
                        : getFavoriteIcon('outlined')
                }
                type='primary'
                ghost
                onClick={onFavoritesClick}
                className='button--header button--favorite'
            >
                {t('layout.favorites')}
            </Button>
        </div>
    )
}
