import { Col, Row, Spin } from 'antd'
import React from 'react'

export default function Loader(props: { size?: 'default' | 'small' | 'large' }) {
    return (
        <Row justify='space-around' align='middle' className='App--loading'>
            <Col style={{ justifyContent: 'space-around', alignItems: 'center' }}>
                <Spin size={props.size || 'large'} />
            </Col>
        </Row>
    )
}
