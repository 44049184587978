import React from 'react'
import { AppstoreOutlined } from '@ant-design/icons'
import { getSwipeIcon } from 'src/utils/icon'
import useCustomHistory from 'src/utils/custom-history-hook'
import { Button, Row } from 'antd'

export default function ToggleViewButtons() {
    const customHistory = useCustomHistory()

    return (
        <>
            <Row>
                <Button
                    icon={getSwipeIcon()}
                    onClick={() => customHistory.push('/swipe')}
                    className='button--header button--icon'
                />
            </Row>
            <Row>
                <Button
                    icon={
                        <AppstoreOutlined
                            className='button--switch-view-catalog'
                            style={{ fontSize: 24 }}
                        />
                    }
                    onClick={() => customHistory.push('/catalog')}
                    className='button--header button--icon'
                />
            </Row>
        </>
    )
}
