import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import FavoritesCatalog from 'src/containers/catalog/FavoritesCatalog'

import { trackPage } from 'src/utils/tracking'

const FavoritesPage: React.FunctionComponent = () => {
    const favorites = useSelector((state: State.Root) => state.favorites)

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [favorites.isOutfit])

    return <FavoritesCatalog />
}

export default FavoritesPage
