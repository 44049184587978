import { PRIMARY_TYPES } from 'src/settings/global'
import store, { useAppSelector } from 'src/store'

export const isPrimaryTypeFromConfig = (type: string, primaryTypes = PRIMARY_TYPES) => {
    let result = false

    for (let i = 0; i < primaryTypes.length; i++) {
        const primaryType = primaryTypes[i]

        if (Array.isArray(primaryType)) {
            result = primaryType.includes(type)
            if (result === true) {
                break
            }

            continue
        }

        result = primaryType === type
        if (result === true) {
            break
        }
    }

    return result
}

export const getTypesFromPrimariesFromConfig = (type: string, primaryTypes = PRIMARY_TYPES) => {
    let result = [type]
    for (let i = 0; i < primaryTypes.length; i++) {
        if (Array.isArray(primaryTypes[i]) && primaryTypes[i].includes(type)) {
            result = primaryTypes[i] as string[]
            break
        }

        if (type === primaryTypes[i]) {
            break
        }
    }

    return result
}

// ---- Type is concidered as dress if it's in the primaryTypes and not of Array type ----
export const isTypeAsDressFromConfig = (type: string, primaryTypes = PRIMARY_TYPES) => {
    let result = false
    for (let i = 0; i < primaryTypes.length; i++) {
        if (Array.isArray(primaryTypes[i])) {
            continue
        }

        if (type === primaryTypes[i]) {
            result = true
            break
        }
    }

    return result
}

export const lookHasDressTypeFromConfig = (
    look: Models.LookRequest,
    primaryTypes = PRIMARY_TYPES
) => {
    let hasDressType = false
    for (let i = 0; i < primaryTypes.length; i++) {
        if (Array.isArray(primaryTypes[i])) {
            continue
        }

        if (look[(primaryTypes[i] as string).toLowerCase()]) {
            hasDressType = true
            break
        }
    }
    return hasDressType
}

export const getPrimaryTypesForLook = (look: Models.LookRequest) => {
    // ---- Var init ----
    const primaryTypes = store.getState().profile?.company?.primary_types || PRIMARY_TYPES
    const lookKeys = Object.keys(look)
    const lookPrimaryTypes: string[] = []

    // ---- For each keys from the look Object we check if we have the current type ----
    for (let i = 0; i < lookKeys.length; i++) {
        // ---- We store the primary look keys in case we don't have the currentType we can set it directly ----
        if (isPrimaryTypeFromConfig(lookKeys[i].toUpperCase(), primaryTypes)) {
            lookPrimaryTypes.push(lookKeys[i])
        }
    }

    return lookPrimaryTypes
}

export default function useTypeFunctions() {
    const company = useAppSelector((state) => state.profile.company)
    const configPrimaryTypes = company.primary_types || PRIMARY_TYPES
    const primaryAndSecondary = company.primary_types
        ? [...company.primary_types, ...company.secondary_types]
        : PRIMARY_TYPES

    const isPrimaryType = (type: string) => isPrimaryTypeFromConfig(type, configPrimaryTypes)

    const isPrimaryOrSecondaryType = (type: string) =>
        isPrimaryTypeFromConfig(type, primaryAndSecondary)

    const getTypesFromPrimaries = (type: string) =>
        getTypesFromPrimariesFromConfig(type, configPrimaryTypes)

    const getTypesFromPrimariesAndSecondaries = (type: string) =>
        getTypesFromPrimariesFromConfig(type, primaryAndSecondary)

    const isTypeAsDress = (type: string) => isTypeAsDressFromConfig(type, configPrimaryTypes)

    const lookHasDressType = (look: Models.LookRequest) =>
        lookHasDressTypeFromConfig(look, configPrimaryTypes)

    return {
        isPrimaryType,
        isPrimaryOrSecondaryType,
        getTypesFromPrimaries,
        getTypesFromPrimariesAndSecondaries,
        isTypeAsDress,
        lookHasDressType,
    }
}
