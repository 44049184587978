import { createContext, useContext } from 'react'

export interface FiltersContextType {
    localFilters: { [key: string]: Models.Facet[] } | null
    onSubHeaderClick?: (facetValue: string) => void
    onlyOneSubCategory?: boolean
    onFilterClick?: (show: boolean) => void
}

const FiltersContext = createContext<FiltersContextType | null>(null)

export const useFiltersContext = () => useContext(FiltersContext)

export default FiltersContext
