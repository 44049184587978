import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleModalAction } from 'src/store/actions/modal'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import Slider from 'react-slick'
import ImageSmooth from './image/ImageSmooth'
import { resizeImage } from 'src/utils/image'
import CardFavorites from './card/cardFavorites'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { GetLookCall } from 'src/store/actions/look'
import useCustomHistory from 'src/utils/custom-history-hook'
import useShowHideHeader from 'src/utils/showHideHeader'
import { Button } from 'antd'

let checkForDragCardCarousel

export default function ModalAddedCart() {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const customHistory = useCustomHistory()
    const showHideHeaderScrollListener = useShowHideHeader()

    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookRecommendations = useSelector((state: State.Root) => state.look?.recommendations)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const cartAdded = useSelector((state: State.Root) => state.garment?.cartAdded)

    const [arrowVerticalPos, setArrowVerticalPos] = useState<number>(null)
    const [currentSlide, setCurrentSlide] = useState(0)

    useEffect(() => {
        let interval = null
        function handleResize() {
            const content = document.getElementById('modalCarouselContainer')
            if (content) {
                clearInterval(interval)
                // We don't need to add the 8px padding from the bottom because it starts above it
                setArrowVerticalPos((window.innerWidth < 728 ? 0 : 24) + content.clientHeight / 2)
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const elementToListen = [
            document,
            document.getElementById('modalCarouselContainer'),
            // Attention, ces elements doivent aussi etre present dans showHideHeader.ts
        ]

        // ---- Branchements des listeners ----
        for (const element of elementToListen) {
            if (element) {
                element.addEventListener('scroll', showHideHeaderScrollListener)
            }
        }
        return () => {
            for (const element of elementToListen) {
                if (element) {
                    element.removeEventListener('scroll', showHideHeaderScrollListener)
                }
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        document.getElementById('modalCarouselContainer')?.scrollTo({ top: 1 })
        // eslint-disable-next-line
    }, [lookRecommendations])

    const closeModal = () => {
        dispatch(handleModalAction(false))
    }

    const handleContinueShop = (e) => {
        e.stopPropagation()
        trackEvent('Continue shopping Clicked', [look], 'Popin Cart')
        closeModal()
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [look], 'Popin Cart')
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    const handleLookClick = (e: React.MouseEvent<HTMLElement>, newLook: Models.Look) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }
        const scrollElement = document.getElementById('layoutScrollableContent')
        scrollElement.scrollTo({ top: 1 })
        trackEvent('Outfit Detailed', newLook, 'Popin Cart')
        dispatch(GetLookCall(newLook.look_id))
        customHistory.push('/swipe')
        closeModal()
    }

    const CarouselNextArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            trackEvent(
                'Swipe right',
                { swipe_from: 'arrow', swipe_index: currentSlide + 1 },
                'Popin Cart'
            )
            onClick(e)
        }

        return (
            <div
                className={`${className} modal--arrow modal--right`}
                style={{ bottom: arrowVerticalPos }}
                onClick={handleClick}
            >
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            trackEvent(
                'Swipe left',
                { swipe_from: 'arrow', swipe_index: currentSlide - 1 },
                'Popin Cart'
            )
            onClick(e)
        }

        return (
            <div
                className={`${className} modal--arrow modal--left`}
                style={{ bottom: arrowVerticalPos }}
                onClick={handleClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    const settings = {
        infinite: false,
        lazyLoad: true,
        swipeToSlide: true,
        slidesToShow: window.innerWidth < 768 ? 1 : 3,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            trackEvent(
                `Swipe ${adaptedDirection}`,
                {
                    swipe_from: 'image',
                    swipe_index: currentSlide + (adaptedDirection == 'right' ? 1 : -1),
                },
                'Popin Cart'
            )
        },
        beforeChange: (current, next) => {
            setCurrentSlide(next)
        },
        arrows: true,
        touchThreshold: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    }

    const lookImgRatio = 100 / (company?.look_image_ratio || 0.66)
    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const multipleGarmentAdded = cartAdded.length > 1

    return (
        <div
            className={`modal--container ${
                lookRecommendations?.length === 0 || !lookRecommendations
                    ? 'modal--no-recommendation'
                    : ''
            }`}
        >
            <div className='modal--title'>
                {t(`cart.modal.title${multipleGarmentAdded ? '_more' : '_one'}`)}
            </div>
            <div className='modal--button-container'>
                <Button
                    className='button card--light modal--button'
                    onClick={handleContinueShop}
                    ghost
                >
                    {t('cart.modal.continue_shop')}
                </Button>
                <Button
                    className='button--default modal--button button--main'
                    onClick={handleGoToCart}
                    href={cartUrl}
                    target={company.link_target_parent ? '_parent' : '_blank'}
                    type='primary'
                >
                    {t('cart.go_to_cart')}
                </Button>
            </div>
            {lookRecommendations && lookRecommendations.length !== 0 && (
                <>
                    <div className='modal--title'>{t('cart.modal.client_matched_with')}</div>
                    <div id='modalCarouselContainer' className='modal--carousel-container'>
                        <Slider {...settings}>
                            {lookRecommendations?.map((localLook) => (
                                <div key={localLook.look_id} style={{ width: '100%' }}>
                                    <div
                                        className='card-model--image-container modal--image-container'
                                        onMouseDown={mouseDownCoords}
                                        onMouseUp={(e) => handleLookClick(e, localLook)}
                                    >
                                        <div className='card-model--image'>
                                            <ImageSmooth
                                                className='card-model--background'
                                                src={
                                                    localLook.image_urls
                                                        ? resizeImage(localLook.image_urls[0], {
                                                              width: 800,
                                                          })
                                                        : 'none'
                                                }
                                                ratio={lookImgRatio}
                                                loader={true}
                                                cover
                                            />
                                        </div>
                                    </div>
                                    <CardFavorites
                                        data={localLook[localLook.recommendation_type]}
                                        ratio={garmentImgRatio}
                                        isOutfit={false}
                                        noClick
                                        seeDetailClick={closeModal}
                                        customImageWidth='50%'
                                        eventCategory='Popin Cart'
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </>
            )}
        </div>
    )
}
