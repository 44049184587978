import { Dispatch } from 'redux'
import { GetLookRecommandationsService, GetLookService } from '../../services/look'
import { getQueryGender, getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'

import {
    INC_LOOK_LOADING,
    SET_QUERY_LOOK_ID,
    FETCH_CURRENT_LOOK,
    SET_LOOK_INDEX,
    FETCH_RECOMMENDATIONS,
    SET_LOOK_REQUEST,
    SET_GARMENT_HISTORY,
    SET_USER_TUCK,
} from './index'
import { SetError } from './error'
import {
    getPrimaryTypesForLook,
    isPrimaryTypeFromConfig,
    isTypeAsDressFromConfig,
    lookHasDressTypeFromConfig,
} from 'src/utils/typeMethods-hook'
import { FetchTypeGarmentAction } from './garment'
import { getUserModel } from 'src/utils/localStorageHelpers'

export const IncLookLoadingAction: Types.ActionFactory<number> = (payload) => ({
    type: INC_LOOK_LOADING,
    payload,
})

export const SetQueryLookIdAction: Types.ActionFactory<string> = (payload) => ({
    type: SET_QUERY_LOOK_ID,
    payload,
})

export const FetchCurrentLookAction: Types.ActionFactory<State.Look> = (payload) => ({
    type: FETCH_CURRENT_LOOK,
    payload,
})

export const FetchRecommendationsAction: Types.ActionFactory<Models.Look[]> = (payload) => ({
    type: FETCH_RECOMMENDATIONS,
    payload,
})

export const SetLookIndexAction: Types.ActionFactory<number> = (payload) => ({
    type: SET_LOOK_INDEX,
    payload,
})

export const SetLookRequest: Types.ActionFactory<Models.Look> = (payload) => ({
    type: SET_LOOK_REQUEST,
    payload,
})

export const SetGarmentHistory: Types.ActionFactory<{ type: string; garment: Models.Garment }> = (
    payload
) => ({
    type: SET_GARMENT_HISTORY,
    payload,
})

export const SetUserTuckAction: Types.ActionFactory<'tuck' | 'untuck'> = (payload) => ({
    type: SET_USER_TUCK,
    payload,
})

export const GetLookCall =
    (params?: string, retry = 0, ignoreCurrentUpdate = false, ignoreMode = true, focus?: string) =>
    async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
        const company = getState().profile.company
        const genderFilterValue: string = getQueryGender(company?.genders)
        const overrideFilterValue = genderFilterValue && { gender: genderFilterValue }

        // ---- Recuperation du look ----
        const look = getState().look?.current

        // ---- Recuperation du current request
        const currentRequest = getState().look.request

        // ---- Construction des params ----
        let changed = !look
        const finalParams: any = {}
        if (params) {
            if (!look || look.look_id != params) {
                finalParams.look_id = params
                changed = true
            }
        } else if (currentRequest !== null) {
            for (const field in currentRequest) {
                let parsedField = field
                const parsedValue = currentRequest[field]

                if (parsedValue) {
                    if (company?.garment_types.indexOf(field.toUpperCase()) !== -1) {
                        parsedField = `${field}_garment_id`
                        finalParams[parsedField] = parsedValue.garment_id

                        continue
                    }

                    if (field === 'model') {
                        parsedField = `${field}_id`
                        finalParams[parsedField] = currentRequest.model.model_id

                        continue
                    }

                    if ((field === 'mode' && !ignoreMode) || field === 'outerwear_garment_id') {
                        finalParams[parsedField] = parsedValue

                        continue
                    }
                }
            }
            changed = true
        } else if (currentRequest === null && !retry) {
            for (const field of ['model_id', 'garment_id']) {
                if (getQueryValue(field) !== null) {
                    finalParams[field] = getQueryValue(field)
                }
            }

            // ---- If we don't have a model_id in query ----
            if (!finalParams['model_id']) {
                const storageModel = getUserModel()

                if (storageModel) {
                    finalParams['model_id'] = storageModel.modelId
                    finalParams['identity_id'] = storageModel.identityId
                }
            }
        }

        // ---- Seulement si on a un changement ----
        if (changed || getState().look?.loading) {
            // ---- Loading ----
            dispatch(IncLookLoadingAction(1))

            // ---- Process ----
            const res: any = await GetLookService({ ...finalParams, ...overrideFilterValue, focus })
            if ([200, 201, 204].includes(res.status)) {
                dispatch(FetchCurrentLookAction(res.data))

                const lookIndex = getState().look?.index
                if (!res.data.image_urls || typeof res.data.image_urls[lookIndex] === 'undefined') {
                    dispatch(SetLookIndexAction(0))
                }

                // ---- Si c'est la derniere réponse de look ----
                if (getState().look.loading < 2) {
                    // Update the Garment History for the StyleBar
                    company.garment_types.forEach((localType) => {
                        const lowerType = localType.toLowerCase()
                        if (res.data[lowerType]) {
                            dispatch(
                                SetGarmentHistory({ type: localType, garment: res.data[lowerType] })
                            )
                        }
                    })
                    // ---- Parsing et mise a jour du request ----
                    dispatch(SetLookRequest(res.data))
                }

                // ---- Current Type Validation ----
                const currentType = getState().garment.type

                // ---- We only need to verify if it's a primary type ----
                if (
                    res.data &&
                    company.primary_types &&
                    isPrimaryTypeFromConfig(currentType, company.primary_types)
                ) {
                    const lookPrimaryTypes = getPrimaryTypesForLook(res.data)

                    // ---- If we don't have the currentType in the keys we set the first primary of the look ----
                    if (!lookPrimaryTypes.includes(currentType.toLowerCase())) {
                        dispatch(FetchTypeGarmentAction(lookPrimaryTypes[0].toUpperCase()))
                    }
                }

                dispatch(IncLookLoadingAction(-1))
                return true
            } else if ([400].includes(res.status) && !retry) {
                const errorDetail = {}
                if (res.data && res.data.message) {
                    errorDetail['error_message'] = res.data.message
                }
                for (const key in finalParams) {
                    errorDetail[`error_${key}`] = finalParams[key]
                }
                trackEvent('Outfit Error', errorDetail, 'Outfit')

                // ---- Si il y a d'autre look loading on ne retry pas ----
                if (getState().look.loading < 2) {
                    // @ts-ignore
                    dispatch(GetLookCall(null, retry + 1, ignoreCurrentUpdate))
                }
            } else {
                dispatch(SetError({ message: res.data.message || res.data.title }))
            }

            // ---- Fin du loading ----
            dispatch(IncLookLoadingAction(-1))
        }
        return false
    }

export const GetLookFromQueryCall =
    (lookId: any) => async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
        dispatch(SetQueryLookIdAction(lookId))
        if (getState().profile.company) {
            // @ts-ignore
            dispatch(GetLookCall(lookId ? { look_id: lookId } : null))
        }
    }

export const GetLookRecommendationsCall =
    (garment_id: string | string[], look_id: string) =>
    async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
        const res: any = await GetLookRecommandationsService({
            identity_id: getState().look.current.model.identity_id,
            garment_id,
            look_id,
        })
        if ([200, 201, 204].includes(res.status)) {
            dispatch(FetchRecommendationsAction(res.data))
        } else {
            //TODO: ERROR HANDLING TRACK EVENT
        }
    }

export const HandleLookRequest =
    (payload: { lookRequest: Models.LookRequest; keepAdditional?: boolean; focus?: string }) =>
    (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
        const state = getState().look
        const company = getState().profile.company
        const newRequest = { ...state.request, ...payload.lookRequest }
        if (
            state.request &&
            lookHasDressTypeFromConfig(payload.lookRequest, company.primary_types) &&
            (state.request['top'] || state.request['bottom'])
        ) {
            delete newRequest['top']
            delete newRequest['bottom']
        }

        if (state.request && isTypeAsDressFromConfig(payload.focus, company.primary_types)) {
            company.primary_types.forEach((dressType) => {
                if (
                    !Array.isArray(dressType) &&
                    newRequest[dressType.toLowerCase()] &&
                    dressType !== payload.focus
                ) {
                    delete newRequest[dressType.toLowerCase()]
                }
            })
        }

        if (
            state.request &&
            (payload.lookRequest['top'] || payload.lookRequest['bottom']) &&
            lookHasDressTypeFromConfig(state.request, company.primary_types)
        ) {
            company.primary_types.forEach((dressType) => {
                if (!Array.isArray(dressType)) {
                    delete newRequest[dressType.toLowerCase()]
                }
            })
        }

        // ---- Override mode value with user value if it exists AND we did not ask for a specific mode value ----
        if (state.userTuck && !payload.lookRequest.mode) {
            newRequest.mode = state.userTuck
        }

        // ---- Fill missing top or bottom garment when needed ----
        if (newRequest['top'] && !newRequest['bottom'] && !state.request['bottom']) {
            newRequest['bottom'] = state.garmentsHistory['BOTTOM']
        }
        if (payload.lookRequest['bottom'] && !newRequest['top'] && !state.request['top']) {
            newRequest.bottom = state.garmentsHistory['TOP']
        }

        // Update the Garment History for the StyleBar
        company.garment_types.forEach((localType) => {
            const lowerType = localType.toLowerCase()
            // We delete the outerwear whenever we change the look and we did not ask for a new outerwear
            if (
                state.request &&
                !payload.keepAdditional &&
                !isPrimaryTypeFromConfig(localType, company.primary_types) &&
                !payload.lookRequest[lowerType]
            ) {
                delete newRequest[lowerType]
            }
            if (newRequest[lowerType]) {
                dispatch(SetGarmentHistory({ type: localType, garment: newRequest[lowerType] }))
            }
        })

        dispatch(SetLookRequest(newRequest))
        dispatch(
            // @ts-ignore
            GetLookCall(
                undefined,
                undefined,
                undefined,
                !payload.lookRequest.mode && !state.userTuck ? true : false,
                payload.focus
            )
        )
    }
