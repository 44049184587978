import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import { Row } from 'antd'

import { AddFavoritesItemsAction, RemoveFavoritesItemsAction } from 'src/store/actions/favorites'
import PoweredBy from 'src/components/PoweredBy'
import { useLocation } from 'react-router-dom'
import CardCart from 'src/components/card/cardCart'

const CartPage = React.forwardRef<HTMLDivElement>((_, ref) => {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const look = useSelector((state: State.Root) => state.look?.request)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const favorites = useSelector((state: State.Root) => state.favorites).items
    const [sizes, setSizes] = useState({})
    const firstCardFocusFunc = useRef(null)

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const garmentTypes = company.garment_types
        ? company.garment_types
        : ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']

    useEffect(() => {
        const newSizes = {}
        for (const type of garmentTypes) {
            const typeLower = type.toLowerCase()
            if (sizes[type] && look[typeLower]) {
                newSizes[type] = sizes[type]
            }
        }
        setSizes(newSizes)
        // eslint-disable-next-line
    }, [look])

    if (!look) {
        return (
            <div className='cart cart--container'>
                <div className='cart--empty'>{t('cart.no_products')}</div>
            </div>
        )
    }

    const handleSizeChange = (type, value) => {
        setSizes({ ...sizes, [type]: value })
    }

    const addOnFavorites = (current: Models.Garment) => {
        dispatch(AddFavoritesItemsAction([...favorites, current] as any))
    }

    const removeOnFavorites = (current: Models.Garment) => {
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.garment_id === current.garment_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        dispatch(RemoveFavoritesItemsAction(dataList))
    }

    return (
        <div
            ref={ref}
            className='cart cart--container'
            id='cartContainerId'
            style={location.pathname !== '/swipe' ? { minHeight: 0 } : {}}
        >
            <div>
                <Row className='cart'>
                    {garmentTypes.map((type, i) => {
                        const garment = look[type.toLowerCase()]
                        if (garment) {
                            return (
                                <CardCart
                                    addOnFavorites={addOnFavorites}
                                    removeFromFavorites={removeOnFavorites}
                                    favorites={favorites}
                                    ratio={garmentImgRatio}
                                    key={garment.garment_id}
                                    garment={garment}
                                    value={sizes[type] || null}
                                    onChange={(value) => handleSizeChange(type, value)}
                                    cardFocusFunc={!i ? firstCardFocusFunc : null}
                                    showRecommendation={window.innerWidth < 768 ? false : true}
                                />
                            )
                        }
                    })}
                </Row>
            </div>
            <PoweredBy />
        </div>
    )
})

CartPage.displayName = 'CartPage'

export default CartPage
