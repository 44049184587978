import React from 'react'
import { trackEvent } from 'src/utils/tracking'
import useCustomHistory from 'src/utils/custom-history-hook'
import useCustomTranslation from 'src/utils/translation'
import { useAppSelector } from 'src/store'
import { getFavoriteIcon } from 'src/utils/icon'
import { CloseOutlined } from '@ant-design/icons'
import PoweredBy from './PoweredBy'
import useTypeClick from 'src/utils/typeClick-hook'
import ExperienceSelect from './select/ExperienceSelect'
import ExitButton from './button/ExitButton'
import { Button } from 'antd'

interface DrawerContentProps {
    onClose: (value: boolean) => void
}

export default function DrawerContent(props: DrawerContentProps) {
    const customHistory = useCustomHistory()
    const { t } = useCustomTranslation()
    const { typeClick } = useTypeClick()
    const favorites = useAppSelector((state) => state.favorites)
    const company = useAppSelector((state) => state.profile?.company)

    const { onClose } = props

    const handleViewCatalogClick = (e) => {
        e.preventDefault()
        trackEvent('Catalog Clicked', {}, 'Burger Menu')
        onClose(false)
        if (location.pathname !== '/catalog') {
            return customHistory.push('/catalog')
        }
    }

    const handleFavoritesClick = (e) => {
        e.preventDefault()
        trackEvent(
            'Favorite Clicked',
            { favorite_count: favorites?.outfits?.length + favorites?.items?.length },
            'Burger Menu'
        )
        onClose(false)
        if (location.pathname !== '/favorites') {
            return customHistory.push('/favorites')
        }
    }

    const handleTypeClick = (type) => {
        trackEvent('Type Clicked', { catalog_type: type }, 'Burger Menu')
        typeClick(type)
        customHistory.push('/swipe')
        onClose(false)
    }

    return (
        <div>
            <div className='drawer--header'>
                <div className='layout--header-container'>
                    <ExitButton category='Burger Menu' />
                    <h1 className={`title title--h1`}>{t('layout.see_the_look')}</h1>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={() => onClose(false)}
                        type='link'
                        className='drawer--close-button'
                    />
                </div>
            </div>
            <div className='drawer--content'>
                {company.garment_types.map((type) => (
                    <Button
                        key={type}
                        className='drawer--type-button'
                        type='link'
                        onClick={() => handleTypeClick(type)}
                    >
                        <div>{t(`layout.${type.toLowerCase()}`)}</div>
                    </Button>
                ))}
                <div className='drawer--category-container'>
                    <div className='drawer--button-container'>
                        <ExperienceSelect category='Burger Menu' />
                        <Button
                            onClick={handleViewCatalogClick}
                            className='button--drawer'
                            type='link'
                        >
                            {t('layout.model_view')}
                        </Button>
                        <Button
                            icon={
                                favorites.outfits.length || favorites.items.length
                                    ? getFavoriteIcon('filled')
                                    : getFavoriteIcon('outlined')
                            }
                            onClick={handleFavoritesClick}
                            className='button--drawer'
                            type='link'
                        >
                            {t('layout.favorites')}
                        </Button>
                    </div>
                </div>
                <div className='drawer--category-container'>
                    <PoweredBy mobile overrideClassName='drawer--poweredby-mobile' />
                </div>
            </div>
        </div>
    )
}
