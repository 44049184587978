import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'src/store'
import useCustomHistory from 'src/utils/custom-history-hook'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import useTypeClick from 'src/utils/typeClick-hook'
import StyleBarTile from './StyleBarTile'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { useDispatch } from 'react-redux'
import { SetGarmentHistory } from 'src/store/actions/look'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { getUserModel, saveUserModel } from 'src/utils/localStorageHelpers'

interface StyleBarProps {
    height?: number
}

export default function StyleBar(props: StyleBarProps) {
    const customHistory = useCustomHistory()
    const { t } = useCustomTranslation()
    const { styleBarTypeClick } = useTypeClick()
    const dispatch = useDispatch()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()

    const garmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const lookRequest = useAppSelector((state) => state.look.request)
    const look = useAppSelector((state) => state.look)
    const garmentsHistory = useAppSelector((state) => state.look.garmentsHistory)
    const currentType = useAppSelector((state) => state.garment.type)

    const [styleScrollLeft, setStyleScrollLeft] = useState<number>(0)
    const [styleClientWidth, setStyleClientWidth] = useState<number>(0)
    const [styleScrollWidth, setStyleScrollWidth] = useState<number>(0)
    const styleBarRef = useRef<HTMLDivElement>()

    const [getGarmentsTrigger] = useCustomGetGarments()

    const { height } = props

    const handleModelClick = () => {
        trackEvent('Choose model Clicked', lookRequest, 'Style Bar')
        customHistory.push('/model')

        // ---- Add current model in local storage if it doesn't exist ----
        const localStorageModel = getUserModel()
        if (!localStorageModel) {
            saveUserModel(look.request.model)
        }
    }

    const handleGarmentClick = (e: React.MouseEvent, type: string, iconClick?: boolean) => {
        e.stopPropagation()
        if (iconClick) {
            trackEvent(
                'Show Type Changed',
                {
                    look: lookRequest,
                    show_type: type,
                    show_value: lookRequest[type.toLowerCase()] ? 'off' : 'on',
                },
                'Style Bar'
            )
        } else {
            trackEvent('Type Clicked', { catalog_type: type }, 'Style Bar')

            if (!['/swipe', '/catalog'].includes(location.pathname)) {
                customHistory.goBack()
            }
        }
        styleBarTypeClick(type, iconClick)
    }

    const onLeftArrowClick = () => {
        styleBarRef.current.scrollTo(
            styleScrollLeft < styleBarRef.current.clientWidth
                ? 0
                : styleScrollLeft - styleBarRef.current.clientWidth,
            0
        )
    }

    const onRightArrowClick = () => {
        styleBarRef?.current.scrollTo(
            styleScrollLeft > styleScrollWidth - styleClientWidth
                ? styleScrollWidth
                : styleScrollLeft + styleClientWidth,
            0
        )
    }

    // Store the different content width values in state so it update the check on the arrows condition
    const updateStylBarValues = (content: HTMLElement) => {
        setStyleScrollLeft(Math.ceil(content.scrollLeft))
        setStyleScrollWidth(content.scrollWidth)
        setStyleClientWidth(content.clientWidth)
    }

    // ---- Populate GarmentHistory at initialisation if there is empty type ----
    useEffect(() => {
        garmentTypes.forEach((garmentType) => {
            if (!garmentsHistory || !garmentsHistory[garmentType]) {
                getGarmentsTrigger({
                    type: garmentType,
                    page: 1,
                })
                    .unwrap()
                    .then((response) => {
                        if (response.items && response.items.length > 0) {
                            dispatch(
                                SetGarmentHistory({ type: garmentType, garment: response.items[0] })
                            )
                        }
                    })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let interval = null
        const content = document.getElementById('StyleBarContainerId')

        // Handle Resize of the window
        function handleResize() {
            if (content) {
                clearInterval(interval)
                interval = null
                updateStylBarValues(content)
            }
        }

        // Handle scroll in the stylebar
        function handleStyleScroll() {
            if (content) {
                updateStylBarValues(content)
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        content.addEventListener('scroll', handleStyleScroll)
        return () => {
            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', handleResize)
            content.removeEventListener('scroll', handleStyleScroll)
        }
        // eslint-disable-next-line
    }, [styleBarRef.current])

    return (
        <div
            style={{ height }}
            className={`style-bar--container`}
            id='StyleBarContainerId'
            ref={styleBarRef}
        >
            {styleBarRef.current && garmentsHistory && (
                <>
                    {styleScrollLeft !== 0 && (
                        <div className='style-bar--arrow-container-left' onClick={onLeftArrowClick}>
                            <LeftOutlined />
                        </div>
                    )}
                    <StyleBarTile
                        image={resizeImage(look?.current.image_urls[0], {
                            width: 800,
                        })}
                        title={t('layout.model')}
                        width={height}
                        onClick={handleModelClick}
                        isZoomedTile
                        noIcon
                    />

                    {garmentTypes.map((type) => {
                        const isAdditionnalType =
                            !isPrimaryOrSecondaryType(type) ||
                            lookRequest?.optional_types?.includes(type)
                        return (
                            <StyleBarTile
                                key={type}
                                image={resizeImage(
                                    garmentsHistory[type]?.image_clipping_url ||
                                        garmentsHistory[type]?.image_url,
                                    {
                                        width: 800,
                                    }
                                )}
                                title={t(`layout.${type.toLowerCase()}`)}
                                width={height}
                                onClick={(e) => handleGarmentClick(e, type)}
                                hidden={!lookRequest || !lookRequest[type.toLowerCase()]}
                                removable={isAdditionnalType}
                                onIconClick={
                                    isAdditionnalType
                                        ? (e) => handleGarmentClick(e, type, true)
                                        : undefined
                                }
                                isActive={currentType === type}
                            />
                        )
                    })}
                    {styleScrollLeft + styleClientWidth < styleScrollWidth && (
                        <div
                            className='style-bar--arrow-container-right'
                            onClick={onRightArrowClick}
                        >
                            <RightOutlined />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
