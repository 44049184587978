import store from 'src/store'

export const loadFromLocalStorageArray = (field) => {
    try {
        const serialized = localStorage.getItem(field)
        if (serialized !== null) {
            const unserialized = JSON.parse(serialized)
            if (Array.isArray(unserialized)) {
                return unserialized
            }
        }
    } catch (e) {
        // ---- Cannot use localStorage ----
    }
    return []
}

export const loadFromLocalStorage = (field) => {
    try {
        const serialized = localStorage.getItem(field)
        if (serialized !== null) {
            const unserialized = JSON.parse(serialized)
            return unserialized
        }
    } catch (e) {
        // ---- Cannot use localStorage ----
    }
    return null
}

export const saveIntoLocalStorage = (field, value) => {
    try {
        localStorage.setItem(field, JSON.stringify(value))
    } catch (e) {
        // ---- Cannot use localStorage ----
    }
    return value
}

const getModelStorageKey = (type: 'group' | 'client', id: string) => {
    if (type === 'group') {
        return `groupId_${id}.model`
    }

    return `clientId_${id}.model`
}

export const saveUserModel = (model: Models.ModelProduct | Models.ModelLocalStorage) => {
    // ---- Var init ----
    const company: Models.CompanyProfile = store.getState().profile.company
    let storageKey = getModelStorageKey('client', company.id)
    const storageObject = {
        modelId:
            (model as Models.ModelProduct).model_id || (model as Models.ModelLocalStorage).modelId,
        identityId:
            (model as Models.ModelProduct).identity_id ||
            (model as Models.ModelLocalStorage).identityId,
    }

    // ---- We save on the client key ----
    saveIntoLocalStorage(storageKey, storageObject)

    // ---- If we have a group we save it on the group key as well ----
    if (company.group_id) {
        storageKey = getModelStorageKey('group', company.group_id)
        saveIntoLocalStorage(storageKey, storageObject)
    }
}

export const getUserModel = () => {
    const company: Models.CompanyProfile = store.getState().profile.company

    const clientModel = loadFromLocalStorage(getModelStorageKey('client', company.id))

    // ---- If we have a group id ----
    if (company.group_id) {
        // ---- We look for the group local storage first ----
        const groupModel = loadFromLocalStorage(getModelStorageKey('group', company.group_id))

        // ---- If we found the local groupModel we return it ----
        if (groupModel) {
            // ---- If we have no local clientModel we set it with the groupModel ----
            if (!clientModel) {
                saveUserModel(groupModel)
            }

            return groupModel
        }

        // ---- If we don't have a groupModel but a clientModel, we set the groupModel with the client value ----
        if (clientModel) {
            saveUserModel(clientModel)
        }
    }

    // ---- Return the clientModel if no groupId ----
    return clientModel
}
