import React, { useEffect } from 'react'

import CarouselModel from 'src/components/carousel/carouselModel'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { trackPage } from 'src/utils/tracking'
import useCustomSize from 'src/utils/size'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'

const ModelPage: React.FunctionComponent = () => {
    const { t } = useCustomTranslation()
    const models = useAppSelector((state) => state.model.all)
    const size = useCustomSize()

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    return (
        <div
            className='model model--container'
            style={size.getLayoutScrollableContentStyle('minHeight')}
        >
            <div className='model--title'>{`${t('look.change_model')} (${
                models.total_count
            })`}</div>
            <div className='model--content'>
                <CarouselModel />
            </div>
        </div>
    )
}

export default ModelPage
