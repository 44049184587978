import React from 'react'

import { DownOutlined } from '@ant-design/icons'
import { Checkbox, Collapse } from 'antd'

interface CollpaseCheckboxProps {
    placeholder: string
    options: any
    name: string
    value?: any[]
    onChange(e: any, name: string): void
    disable?: boolean
}

const CollpaseCheckbox: React.FunctionComponent<CollpaseCheckboxProps> = (props: any) => {
    const { placeholder, options, name, value, onChange, disable } = props

    return (
        <div className='collapse'>
            <Collapse
                expandIconPosition='end'
                className='collapse--container'
                expandIcon={() => <DownOutlined />}
                items={[
                    {
                        key: '1',
                        label: placeholder,
                        children: options.map((item: any, itemKey: number) => (
                            <Checkbox
                                key={itemKey}
                                checked={value?.includes(item.value)}
                                onChange={(e) => onChange(item.value, name, e)}
                                disabled={item.count === 0 || disable}
                                className='collapse--checkbox'
                            >
                                <div>
                                    {`${item.label ? item.label : item.value} ${
                                        item.count !== null ? `(${item.count})` : ''
                                    }`}
                                </div>
                            </Checkbox>
                        )),
                    },
                ]}
            />
        </div>
    )
}

export default CollpaseCheckbox
