import React, { useMemo } from 'react'
import { useGetExperiencesQuery } from 'src/store/api/api-experience'
import { useAppSelector } from 'src/store'
import { updateSearchString } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import { Select } from 'antd'

const { Option } = Select

interface ExperienceSelectProps {
    category?: string
}

export default function ExperienceSelect(props: ExperienceSelectProps) {
    const { t } = useCustomTranslation()
    const company = useAppSelector((state) => state.profile.company)
    const look = useAppSelector((state) => state.look?.request)
    const { category } = props

    const { data: experiences } = useGetExperiencesQuery()

    const foundExperience = useMemo(() => {
        if (experiences && company) {
            return experiences.find((exp) => exp.experience_id === company?.experience_id)
        }

        return null
    }, [company, experiences])

    const handleOnFocus = () => {
        trackEvent('Multi-Experience Click', {}, category || 'Menu')
    }

    const handleOnChange = (newExperienceId: string) => {
        if (company?.experience_id == newExperienceId) {
            return
        }
        trackEvent('Multi-Experience Selected', {}, category || 'Menu')
        window.open(
            '/?' +
                updateSearchString({
                    experience_id: newExperienceId,
                    look_id: null,
                    type: null,
                    model_id: look?.model?.model_id,
                }),
            '_self'
        )
    }
    return (
        <>
            {foundExperience && (
                <Select
                    className='select--experience'
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    placeholder={t('layout.other_selection')}
                    bordered={false}
                >
                    {experiences &&
                        experiences.map((localExperience) => (
                            <Option
                                key={localExperience.experience_id}
                                value={localExperience.experience_id}
                                disabled={company?.experience_id == localExperience.experience_id}
                            >
                                {company?.experience_id == localExperience.experience_id ? (
                                    <b>{localExperience.experience_name}</b>
                                ) : (
                                    localExperience.experience_name
                                )}
                            </Option>
                        ))}
                </Select>
            )}
        </>
    )
}
