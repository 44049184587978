import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { trackEvent, trackPage } from 'src/utils/tracking'
import CartPage from './cart'
import LookContainer from 'src/containers/look/Look'
import Loader from 'src/components/Loader'
import CarouselGarment from 'src/components/carousel/carouselGarment'
import { SetGarmentHistory } from 'src/store/actions/look'
import { calcMaxWidth } from 'src/utils/calc-max-width'
import useCustomSize from 'src/utils/size'
import PoweredBy from 'src/components/PoweredBy'
import { countActiveFilters, getAllGarments } from 'src/store/slices/databaseSlice'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import StyleBar from 'src/components/stylebar/StyleBar'
import SubHeader from 'src/components/header/SubHeader'
import { useFiltersContext } from 'src/components/LocalFiltersContext'
import FilterButton from 'src/components/button/FilterButton'
import TypeTabs from 'src/components/header/TypeTabs'
import useTypeClick from 'src/utils/typeClick-hook'
import { STYLEBAR_HEIGHT_DESKTOP, STYLEBAR_HEIGHT_MOBILE } from 'src/settings/global'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { Button } from 'antd'

const TOP = 'TOP'
const BOTTOM = 'BOTTOM'
const OUTERWEAR = 'OUTERWEAR'

const SwipePage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const size = useCustomSize()
    const { t } = useCustomTranslation()
    const FiltersContext = useFiltersContext()
    const { typeClick } = useTypeClick()
    const { isTypeAsDress } = useTypeFunctions()
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const allGarmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const garmentsHistory = useAppSelector((state) => state.look.garmentsHistory)
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)
    const company = useSelector((state: State.Root) => state.profile.company)
    const numberActiveFilters = useAppSelector((state) => countActiveFilters(state))

    const showStyleBar = true

    const [getGarmentsTrigger, { isLoading, error }] = useCustomGetGarments()

    const cartRef = useRef(null)
    const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)
    const [hasEnoughWidth, setHasEnoughWidth] = useState<boolean>(false)

    const handleSeeLookDetailClick = () => {
        trackEvent('Outfit Detailed', look, 'Outfit')
        if (cartRef?.current) {
            // If we are on the mobile we use the window scroll and offset it
            if (window.innerWidth < 768) {
                const localContainerElem = document.getElementById('lookContainerId')
                const scrollElement = document.getElementById('layoutScrollableContent')
                // We add 40 because of the padding above the lookContainer and the padding on top of the cart
                return scrollElement.scrollTo({
                    top: localContainerElem.clientHeight + 40,
                    behavior: 'smooth',
                })
            }

            return cartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const handleTypeClick = (value: string) => {
        trackEvent('Type Clicked', { catalog_type: value }, 'Menu')
        typeClick(value)
    }

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let interval = null
        function handleResize() {
            const content = document.getElementById('layoutScrollableContent')
            const localContainerElem = document.getElementById('lookContainerId')
            if (content) {
                /**
                 * After we have the content we don't have directly the localContainer as we need to first set the maxWidth value. So we clear the interval the second time we enter here
                 */
                if (localContainerElem) {
                    clearInterval(interval)
                }
                const tmpStyle = size.getLayoutScrollableContentStyle('height')

                // ---- We calculate the maxWidth WITH TOGGLE to check if there is enough space ----
                const maxWidthWithoutToggle = calcMaxWidth(
                    // Full width - 32 horizontal padding
                    content.clientWidth - 32,
                    window.innerWidth / 4,
                    tmpStyle.height || content.clientHeight,
                    lookRatio,
                    // offset = stylebar desktop ou (stylebar mobile ou typetab) + 8 margin top stylebar +
                    // 34 subheader stylebar + 16 padding top + 56 button detail
                    window.innerWidth >= 768
                        ? showStyleBar
                            ? // We add 24 if we are in hide header so we can always see the stylebar
                              STYLEBAR_HEIGHT_DESKTOP + (tmpStyle.height ? 24 : 0) + 34
                            : 0
                        : (showStyleBar ? STYLEBAR_HEIGHT_MOBILE : 54) +
                              8 +
                              (company.garment_filters && !tmpStyle.height ? 34 : 0) +
                              16 +
                              40
                )
                const enoughWidthWithOffset =
                    localContainerElem &&
                    Math.max(maxWidthWithoutToggle / 3, 150) <
                        (localContainerElem.clientWidth - maxWidthWithoutToggle) / 2
                setHasEnoughWidth(enoughWidthWithOffset)
                setContentMaxWidth(maxWidthWithoutToggle)
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [lookRatio, parentHeight])

    useEffect(() => {
        if (!garmentType || !look || isLoading || error) {
            return
        }

        // We check if we have data for each garment types
        allGarmentTypes.forEach((localType) => {
            if (
                !allGarments[localType] ||
                (look[localType.toLowerCase()] &&
                    !allGarments[localType].all.find(
                        (garment) => garment.garment_id === look[localType.toLowerCase()].garment_id
                    ))
            ) {
                getGarmentsTrigger({
                    garment_id: look[localType.toLowerCase()]?.garment_id,
                    type: localType,
                    page: 1,
                })
                    .unwrap()
                    .then((resp) => {
                        // If we don't have history data yet we populate it with the first garment
                        if (!garmentsHistory[localType] && resp && resp.items.length > 0) {
                            dispatch(SetGarmentHistory({ type: localType, garment: resp.items[0] }))
                        }
                    })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType, look])

    const showSubHeader = useMemo<boolean>(
        () =>
            company.garment_category_facets !== null &&
            company.garment_category_facets !== undefined &&
            company.garment_category_facets[garmentType] !== null,
        [company, garmentType]
    )

    return (
        <>
            {error ? (
                <div>{t('error.error') + (error as API.ErrorQuery).data.message}</div>
            ) : (
                <div
                    id='layoutSwipeContentId'
                    className={`layout--swipe-content`}
                    style={size.getLayoutScrollableContentStyle('minHeight')}
                >
                    {!look ||
                    !garmentType ||
                    ([TOP, BOTTOM].indexOf(garmentType) !== -1 &&
                        (!allGarments.TOP || !allGarments.BOTTOM)) ||
                    !allGarments[garmentType] ? (
                        <div
                            style={{
                                height: `calc(100% - ${
                                    window.innerWidth >= 768
                                        ? STYLEBAR_HEIGHT_DESKTOP
                                        : // Mobile stylebar height + 34 subheader
                                          STYLEBAR_HEIGHT_MOBILE +
                                          (company.garment_filters ? 34 : 0)
                                }px)`,
                            }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {contentMaxWidth && (
                                <>
                                    {[TOP, BOTTOM].indexOf(garmentType) !== -1 && (
                                        <>
                                            <div
                                                className='swipe--carousel swipe--carousel-top'
                                                style={{
                                                    top:
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[`swipe_top_ratio`]?.top || 0.25),
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={TOP}
                                                    cardHeight={
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[`swipe_top_ratio`]?.height || 0.25)
                                                    }
                                                />
                                            </div>
                                            <div
                                                className='swipe--carousel swipe--carousel-bottom'
                                                style={{
                                                    top:
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[`swipe_bottom_ratio`]?.top || 0.5),
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={BOTTOM}
                                                    cardHeight={
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[`swipe_bottom_ratio`]?.height ||
                                                            0.3)
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                    {isTypeAsDress(garmentType) && (
                                        <div
                                            className='swipe--carousel swipe--carousel-top'
                                            style={{
                                                top:
                                                    (contentMaxWidth / lookRatio) *
                                                    (company[
                                                        `swipe_${garmentType.toLowerCase()}_ratio`
                                                    ]?.top || 0.25),
                                            }}
                                        >
                                            <CarouselGarment
                                                type={garmentType}
                                                cardHeight={
                                                    (contentMaxWidth / lookRatio) *
                                                    (company[
                                                        `swipe_${garmentType.toLowerCase()}_ratio`
                                                    ]?.height || 0.5)
                                                }
                                            />
                                        </div>
                                    )}
                                    {garmentType === OUTERWEAR && (
                                        <div
                                            className='swipe--carousel swipe--carousel-top'
                                            style={{
                                                top:
                                                    (contentMaxWidth / lookRatio) *
                                                    (company[`swipe_outerwear_ratio`]?.top || 0.25),
                                            }}
                                        >
                                            <CarouselGarment
                                                type={OUTERWEAR}
                                                cardHeight={
                                                    (contentMaxWidth / lookRatio) *
                                                    (company[`swipe_outerwear_ratio`]?.height ||
                                                        0.25)
                                                }
                                            />
                                        </div>
                                    )}

                                    {/* DEFAULT CASE FOR GARMENT TYPE CAROUSEL */}
                                    {[TOP, BOTTOM, OUTERWEAR].indexOf(garmentType) === -1 &&
                                        !isTypeAsDress(garmentType) && (
                                            <div
                                                className='swipe--carousel swipe--carousel-top'
                                                style={{
                                                    top:
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[
                                                            `swipe_${garmentType.toLowerCase()}_ratio`
                                                        ]?.top || 0.25),
                                                }}
                                            >
                                                <CarouselGarment
                                                    type={garmentType}
                                                    cardHeight={
                                                        (contentMaxWidth / lookRatio) *
                                                        (company[
                                                            `swipe_${garmentType.toLowerCase()}_ratio`
                                                        ]?.height || 0.25)
                                                    }
                                                />
                                            </div>
                                        )}
                                    <LookContainer
                                        swipeStyle
                                        contentMaxWidth={contentMaxWidth}
                                        hasEnoughWidth={hasEnoughWidth}
                                    />
                                </>
                            )}
                            {FiltersContext &&
                                window.innerWidth < 768 &&
                                company.garment_filters &&
                                // Enough data to use Subheader
                                (showSubHeader ? (
                                    <div
                                        className='flex-centered'
                                        style={{ minWidth: 0, marginTop: 8 }}
                                    >
                                        <SubHeader
                                            localFilters={FiltersContext.localFilters}
                                            onClick={FiltersContext.onSubHeaderClick}
                                            onlyOneSubCategory={FiltersContext.onlyOneSubCategory}
                                            overrideClassName='style-bar--subheader'
                                        />
                                        <FilterButton
                                            countFilter={numberActiveFilters}
                                            showOverride
                                            buttonClassNameOverride='layout--header-filter-button'
                                            classNameOverride='style-bar--filter-container'
                                            onClick={() => FiltersContext.onFilterClick(true)}
                                        />
                                    </div>
                                ) : (
                                    <div className='flex-end style-bar--subheader-container'>
                                        <FilterButton
                                            countFilter={numberActiveFilters}
                                            showOverride
                                            buttonClassNameOverride='layout--header-filter-button'
                                            classNameOverride='style-bar--filter-container'
                                            onClick={() => FiltersContext.onFilterClick(true)}
                                        />
                                    </div>
                                ))}
                            {showStyleBar && (
                                <StyleBar
                                    height={
                                        window.innerWidth >= 768
                                            ? STYLEBAR_HEIGHT_DESKTOP
                                            : STYLEBAR_HEIGHT_MOBILE
                                    }
                                />
                            )}

                            {!showStyleBar && window.innerWidth < 768 && (
                                <TypeTabs onTabClick={handleTypeClick} activeType={garmentType} />
                            )}

                            <div
                                className='flex-centered button--mobile'
                                style={{ padding: '0 16px' }}
                            >
                                <Button
                                    className={'button button--detail button--mobile'}
                                    type='primary'
                                    onClick={handleSeeLookDetailClick}
                                >
                                    {t('look.see_look_detail')}
                                </Button>
                            </div>
                            <div className='layout--headroom' ref={cartRef}>
                                <CartPage />
                            </div>
                            <PoweredBy mobile />
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default SwipePage
