import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import { GetGarmentDetailCall } from 'src/store/actions/garment'

import ImagePreviewProduct from 'src/components/image/ImagePreview'

import FilterSelect from 'src/components/select/filter'

import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart, getGarmentSizeLabel } from 'src/utils/garment'
import { trackPage, trackEvent } from 'src/utils/tracking'
import useShowModal from 'src/utils/showModal'
import { Button, Col, Row } from 'antd'

const ProductsPage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const showModal = useShowModal()
    const garment = useSelector((state: State.Root) =>
        state.look.request ? state.look.request[state.garment.type.toLowerCase()] : null
    )
    const detail = useSelector((state: State.Root) => state.garment?.detail)
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const [currentSize, setCurrentSize] = useState(null)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const sizeSelectRef = useRef(null)

    const isPromotion = useMemo(() => {
        if (detail) {
            return (
                !!detail.product_price_original &&
                detail.product_price_original > detail.product_price
            )
        }

        return false
    }, [detail])

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        document.getElementById('layoutScrollableContent').scrollTo({ top: 1 })
        // eslint-disable-next-line
    }, [garment])

    useEffect(() => {
        const fetchData = async (garmentId) => {
            await dispatch(GetGarmentDetailCall(garmentId))
        }
        if (garment && (!detail || detail.garment_id != garment.garment_id)) {
            fetchData(garment.garment_id)
        }
        setGoToCart(false)
        // eslint-disable-next-line
    }, [garment])

    if (!garment || !detail) {
        return <div className='product'></div>
    }

    const optionSize = getGarmentOptionSizes(detail)

    const handleSizeChange = (value) => {
        if (value) {
            trackEvent(
                'Size Selected',
                [
                    garment,
                    {
                        item_size_selected: value,
                        item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                    },
                ],
                'Item Detail'
            )
        }
        setCurrentSize(value)
        setGoToCart(false)
    }

    const handleAddToCart = () => {
        if (!currentSize) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                garment,
                {
                    item_size_selected: currentSize,
                    item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                },
            ],
            'Item Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(garment, currentSize, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        garment,
                        {
                            item_size_selected: currentSize,
                            item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                        },
                    ],
                    'Item Detail'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                showModal(garment.garment_id, garment, 'Item Detail')
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [garment], 'Item Detail')
    }

    const handleExtraProductClick = () => {
        trackEvent('Go to external Clicked', [garment], 'Item Detail')
    }

    return (
        <div className='product'>
            <Row className='product--container'>
                <Col className='product--left-side' xs={{ span: 24 }} xl={{ span: 10 }}>
                    <ImagePreviewProduct garment={detail} />
                </Col>
                <Col className='product--right-side' xs={{ span: 24 }} xl={{ span: 14 }}>
                    {detail.product_brand && (
                        <h2 className='product--brand'>{parse(detail.product_brand)}</h2>
                    )}
                    <h2 className='product--title'>{parse(detail.product_name)}</h2>
                    {isPromotion && (
                        <h2 className='product--price--promotion'>
                            <span className='product--price--original'>
                                {formattedPrice(
                                    detail.product_price_original,
                                    detail.product_currency,
                                    priceFloat
                                )}
                            </span>
                            <span className='product--price--percent'>
                                -
                                {Math.round(
                                    ((detail.product_price_original - detail.product_price) * 100) /
                                        detail.product_price_original
                                )}
                                %
                            </span>
                        </h2>
                    )}
                    <h2
                        className={`product--price--final${
                            isPromotion ? ' product--price--final--promotion' : ''
                        }`}
                    >
                        {formattedPrice(detail.product_price, detail.product_currency, priceFloat)}
                    </h2>
                    {garment.product_external_url && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            className='button--underlined product--extra-product'
                            onClick={handleExtraProductClick}
                            href={garment.product_external_url}
                            target={company.link_target_parent ? '_parent' : '_blank'}
                        >
                            {t('product.extra_product')}
                        </a>
                    )}
                    <p className='product--text'>
                        {parse(
                            detail.product_description_html
                                ? detail.product_description_html
                                : detail.product_description
                        )}
                    </p>
                    <div className='product--bottom-container'>
                        <FilterSelect
                            name='size'
                            ref={sizeSelectRef}
                            options={optionSize}
                            translation={false}
                            placeholder={t('product.choose_size')}
                            value={currentSize}
                            onChange={handleSizeChange}
                        />
                        {goToCart && cartUrl ? (
                            <Button
                                className='button card--main'
                                onClick={handleGoToCart}
                                href={cartUrl}
                                target={company.link_target_parent ? '_parent' : '_blank'}
                                type='primary'
                                key='goToCart'
                            >
                                {t('product.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button card--main'
                                loading={addingToCart}
                                onClick={handleAddToCart}
                                type='primary'
                            >
                                {t('product.add_to_cart')}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProductsPage
