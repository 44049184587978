import React, { useMemo, useRef, useState } from 'react'

import { ZoomInOutlined } from '@ant-design/icons'

import { trackEvent } from 'src/utils/tracking'
import { isGarmentInFavorites } from 'src/utils/favorite'
import { useDispatch, useSelector } from 'react-redux'
import { AddFavoritesItemsAction, RemoveFavoritesItemsAction } from 'src/store/actions/favorites'
import { getFavoriteIcon } from 'src/utils/icon'
import useCustomTranslation from 'src/utils/translation'
import ImageSmooth from './ImageSmooth'
import { resizeImage } from 'src/utils/image'
import Slider from 'react-slick'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { Button, Col, Row, Tooltip } from 'antd'

interface ImagePreviewProductProps {
    garment: Models.GarmentDetail
}

let checkForDragCardCarousel

const ImagePreviewProduct: React.FunctionComponent<ImagePreviewProductProps> = (props) => {
    const { garment } = props
    const dispatch = useDispatch()

    const sliderRef = useRef(null)

    const favorites = useSelector((state: State.Root) => state.favorites).items
    const company = useSelector((state: State.Root) => state.profile?.company)
    const [visible, setVisible] = useState(false)
    const [current, setCurrent] = useState(0)
    const { t } = useCustomTranslation()

    const inFavorites = isGarmentInFavorites(favorites, garment)
    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)
    const imageUrls = useMemo(
        () =>
            garment.image_urls && garment.image_urls.length
                ? garment.image_urls
                : [garment.image_url],
        [garment]
    )

    // ---- array of slides containing image data used by the lightbox ----
    const imagesSlides = useMemo(() => {
        return Array.from(imageUrls, (imageUrl) => {
            return { src: imageUrl }
        })
    }, [imageUrls])

    const addOnFavorites = (e) => {
        e.stopPropagation()
        trackEvent('Item Saved to favorite', garment, 'Item Detail')
        dispatch(AddFavoritesItemsAction([...favorites, garment] as any))
    }

    const removeOnFavorites = (e) => {
        e.stopPropagation()
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.garment_id === garment.garment_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        trackEvent('Item Removed from favorite', garment, 'Item Detail')
        dispatch(RemoveFavoritesItemsAction(dataList))
    }

    const handleZoomClick = (e) => {
        e.stopPropagation()
        trackEvent('Zoom Opened', [garment, { item_index_selected: current }], 'Item Detail')
        setVisible(true)
    }

    const handleDotClick = (e, key) => {
        e.stopPropagation()
        trackEvent(
            'Carousel Selected',
            [garment, { item_index_selected: current, carousel_type: 'dot' }],
            'Item Detail'
        )
        setCurrent(key)
        sliderRef.current?.slickGoTo(key, true)
    }

    const handleThumbnailClick = (e, key) => {
        e.stopPropagation()
        trackEvent(
            'Carousel Selected',
            [garment, { item_index_selected: current, carousel_type: 'thumbnail' }],
            'Item Detail'
        )
        setCurrent(key)
        sliderRef.current?.slickGoTo(key, true)
    }

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        handleZoomClick(e)
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    const settings = {
        focusOnSelect: false,
        slidesToShow: 1,
        onSwipe: (direction) => {
            const adaptedCurrent =
                (current + imageUrls.length + (direction == 'right' ? -1 : 1)) % imageUrls.length
            trackEvent(
                'Carousel Selected',
                [garment, { item_index_selected: adaptedCurrent, carousel_type: 'image' }],
                'Item Detail'
            )
        },
        beforeChange: (unused, next) => {
            setCurrent(next)
        },
    }

    return (
        <>
            <Col className='image image--preview-container'>
                <div className='image--preview-relative override_img_container'>
                    <Slider
                        {...settings}
                        ref={sliderRef}
                        className='image--preview-carousel'
                        key={garment.garment_id}
                    >
                        {imageUrls.length > 0 &&
                            imageUrls.map((imageItem: string) => (
                                <div
                                    className='image--preview-carousel-image'
                                    onMouseDown={mouseDownCoords}
                                    onMouseUp={(e) => handleCardClick(e)}
                                    key={imageItem}
                                >
                                    <ImageSmooth
                                        src={resizeImage(imageItem, { width: 800 })}
                                        ratio={garmentImgRatio}
                                        lazyload={false}
                                    />
                                </div>
                            ))}
                    </Slider>
                    <div className='card-model--top'>
                        <Tooltip
                            trigger={['hover']}
                            placement='left'
                            overlayClassName='card-model card-model--tooltip'
                            title={t('look.favorite_info')}
                        >
                            {!inFavorites ? (
                                <Button
                                    onClick={addOnFavorites}
                                    icon={getFavoriteIcon('outlined')}
                                    type='link'
                                    className='button'
                                />
                            ) : (
                                <Button
                                    icon={getFavoriteIcon('filled')}
                                    type='link'
                                    className='button'
                                    onClick={removeOnFavorites}
                                />
                            )}
                        </Tooltip>
                    </div>
                    <div className='card-model--bottom'>
                        <span className='empty'></span>
                        <Button
                            icon={<ZoomInOutlined />}
                            type='link'
                            className='button'
                            onClick={handleZoomClick}
                        ></Button>
                    </div>
                </div>
                <Lightbox
                    open={visible}
                    close={() => setVisible(false)}
                    slides={imagesSlides}
                    styles={{ container: { backgroundColor: '#000A' } }}
                    plugins={[Zoom as Plugin]}
                />
            </Col>

            {imageUrls.length > 1 && (
                <>
                    <Row className='image--dot-container'>
                        {imageUrls.map((item: string, key: number) => (
                            <div
                                key={item}
                                className={`image--dot ${
                                    key === current ? 'image--dot-active' : ''
                                }`}
                                onClick={(e) => handleDotClick(e, key)}
                            />
                        ))}
                    </Row>
                    <Row className='image--row'>
                        {imageUrls.map((item: string, key: number) => (
                            <div
                                key={item}
                                className='image--minimize override_img_container'
                                onClick={(e) => handleThumbnailClick(e, key)}
                            >
                                <ImageSmooth
                                    src={resizeImage(item, { width: 800 })}
                                    ratio={garmentImgRatio}
                                    lazyload={false}
                                />
                            </div>
                        ))}
                    </Row>
                </>
            )}
        </>
    )
}

export default ImagePreviewProduct
