import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import CardFavorites from '../../components/card/cardFavorites'

import {
    RemoveFavoritesItemsAction,
    AddFavoritesItemsAction,
    RemoveFavoritesOutfitAction,
    AddFavoritesOutfitAction,
    SwitchFavoritesContentAction,
} from 'src/store/actions/favorites'
import useCustomSize from 'src/utils/size'
import { Col, Row } from 'antd'

const FavoritesCatalog: React.FunctionComponent = () => {
    const size = useCustomSize()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const favorites = useSelector((state: State.Root) => state.favorites)
    const company = useSelector((state: State.Root) => state.profile?.company)

    const list = favorites.isOutfit ? favorites.outfits : favorites.items
    const compareField = favorites.isOutfit ? 'look_id' : 'garment_id'

    useEffect(() => {
        if (!favorites.isOutfit && !favorites.items.length && favorites.outfits.length) {
            dispatch(SwitchFavoritesContentAction(true))
        } else if (favorites.isOutfit && !favorites.outfits.length && favorites.items.length) {
            dispatch(SwitchFavoritesContentAction(false))
        }
        // eslint-disable-next-line
    }, [])

    const addOnFavorites = (current: any) => {
        if (favorites.isOutfit) {
            dispatch(AddFavoritesOutfitAction([...list, current] as any))
        } else {
            dispatch(AddFavoritesItemsAction([...list, current] as any))
        }
    }

    const removeOnFavorites = (current: any) => {
        const dataList: any = [...list]
        const currentIndex = dataList.findIndex(
            (item) => item[compareField] === current[compareField]
        )
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        if (favorites.isOutfit) {
            dispatch(RemoveFavoritesOutfitAction(dataList))
        } else {
            dispatch(RemoveFavoritesItemsAction(dataList))
        }
    }

    const imgRatio =
        100 /
        ((favorites.isOutfit ? company?.look_image_ratio : company?.garment_image_ratio) || 0.66)

    return (
        <div
            className={`catalog catalog--container override_catalog_container ${
                favorites.isOutfit ? 'catalog--outfit' : ''
            }`}
            style={size.getLayoutScrollableContentStyle('minHeight')}
        >
            <Row gutter={[16, 24]} justify='start' className='catalog--content'>
                {list &&
                    list.length > 0 &&
                    list.map((item: any, itemKey: number) => (
                        <Col
                            className='catalog--item gutter-row override_catalog_grid_column'
                            span={12}
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            key={item.look_id || item.garment_id || itemKey}
                        >
                            <CardFavorites
                                data={item}
                                favorites={list}
                                addOnFavorites={addOnFavorites}
                                removeFromFavorites={removeOnFavorites}
                                ratio={imgRatio}
                                isOutfit={favorites.isOutfit}
                            />
                        </Col>
                    ))}
            </Row>
            {list && list.length === 0 && (
                <div className='catalog--empty'>
                    {t(
                        favorites.isOutfit
                            ? 'favorite.no_favorite_outfits'
                            : 'favorite.no_favorite_items'
                    )}
                </div>
            )}
        </div>
    )
}

export default FavoritesCatalog
