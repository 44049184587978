import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import CardItem from '../../components/card/cardItem'
import { RemoveFavoritesItemsAction, AddFavoritesItemsAction } from 'src/store/actions/favorites'
import { getAllGarmentsForType } from 'src/store/slices/databaseSlice'
import { useAppSelector } from 'src/store'
import useCustomSize from 'src/utils/size'
import { Col, Row } from 'antd'

export const THRESHOLD_PIXELS = 64

const Catalog: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const size = useCustomSize()
    const { t } = useCustomTranslation()
    const ref: any = useRef()

    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const allGarments = useAppSelector((state) => getAllGarmentsForType(state, garmentType))
    const favorites = useSelector((state: State.Root) => state.favorites).items
    const company = useSelector((state: State.Root) => state.profile?.company)

    const addOnFavorites = (current: Models.Garment) => {
        dispatch(AddFavoritesItemsAction([...favorites, current] as any))
    }

    const removeOnFavorites = (current: Models.Garment) => {
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.garment_id === current.garment_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        dispatch(RemoveFavoritesItemsAction(dataList))
    }

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const data = allGarments

    if (!data || company.garment_types.indexOf(garmentType) === -1) {
        return <div className='catalog catalog--container' ref={ref}></div>
    }

    if (data.length === 0) {
        return (
            <div className='catalog catalog--container' ref={ref}>
                <div className='catalog--empty'>{t('product.no_products')}</div>
            </div>
        )
    }

    return (
        <div
            className='catalog catalog--container override_catalog_container'
            ref={ref}
            style={size.getLayoutScrollableContentStyle('minHeight')}
        >
            <Row gutter={[16, 24]} justify='start' className='catalog--content'>
                {data.map((item: Models.Garment) => (
                    <Col
                        className='catalog--item gutter-row override_catalog_grid_column'
                        span={12}
                        xs={{ span: 12 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                        key={item.garment_id}
                    >
                        <CardItem
                            data={item}
                            favorites={favorites}
                            addOnFavorites={addOnFavorites}
                            removeFromFavorites={removeOnFavorites}
                            ratio={garmentImgRatio}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Catalog
