// @ts-nocheck
import ReactGA from 'react-ga'
import { init, getDeviceId, getSessionId, track } from '@amplitude/analytics-browser'
import { DEBUG_MODE } from 'src/settings/global'
import { getQueryValue, getQueryLocale } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'
import store from 'src/store'
import { PostInitEvent } from 'src/services/event'

const enableAnalytics =
    window.location.hostname !== 'localhost' &&
    ['0', 'false'].indexOf(getQueryValue('analytics')) === -1
const trackingDebug =
    DEBUG_MODE ||
    (!window.location.hostname.match(/^([^.]+\.)?experience\.veesual\.ai$/) &&
        !window.location.hostname.match(
            /^https?:\/\/generate-experience-[0-9a-z]+-veesual\.vercel\.app(\/|\?|#|$)/
        ))

const hasAnalytics = () => {
    return true
}

const hasGoogleAnalytics = () => {
    const googleAnalytics = store.getState().profile?.company?.google_analytics
    if (googleAnalytics !== null) {
        if (
            (Array.isArray(googleAnalytics) && googleAnalytics.length) ||
            (typeof googleAnalytics === 'string' && googleAnalytics !== '')
        ) {
            return true
        }
    }
    return false
}

let areAnalyticsLoaded = false
let areGoogleAnalyticsLoaded = false
const initTracking = () => {
    // ---- Segment ----
    if (hasAnalytics()) {
        if (enableAnalytics) {
            if (trackingDebug) {
                init('14b31c452b81ab5f02276fcba7a343bc', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            } else {
                init('6745d3ed5a0f89fce03654efe4a92e81', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            }
        }
        areAnalyticsLoaded = true
    }

    // ---- GA ----
    if (hasGoogleAnalytics()) {
        if (enableAnalytics) {
            const googleAnalytics = store.getState().profile?.company?.google_analytics
            if (googleAnalytics !== null) {
                ReactGA.initialize(googleAnalytics, { debug: trackingDebug })
            }
        }
        areGoogleAnalyticsLoaded = true
    }
}

const getBasicProperties = () => {
    let origin = getQueryValue('origin')
    if (origin !== null) {
        const originSplit = origin.split('?')
        if (originSplit.length == 2) {
            let originQuery = originSplit[1]
            originQuery = originQuery.replace(
                /(^|&)(utm_[^&#=]*|fbclid|gclid|gclsrc|_ga|mc_cid|mc_eid|_branch_match_id)(=[^&#]*)?/gi,
                ''
            )
            originQuery = originQuery.replace(/^&/, '')
            origin = originSplit[0] + (originQuery != '' ? '?' + originQuery : '')
        }
    }

    const extras = {}
    for (let i = 1; i <= 5; i++) {
        const extraField = `extra_param${i > 1 ? i : ''}`
        const extraValue = getQueryValue(extraField)
        if (extraValue !== null && extraValue !== '') {
            extras[extraField] = extraValue
        }
    }

    const state = store.getState()
    return Object.assign(
        {
            client_id: state.profile.company.id,
            client_internal: state.profile.company.internal,
            client_name: state.profile.company.name,
            group_id: state.profile.company.group_id || null,
            group_name: state.profile.company.group_name || null,
            experience_id: state.profile.company.experience_id || null,
            experience_name: state.profile.company.experience_name || null,
            experience: getQueryValue('experience'),
            locale: getQueryLocale(),
            domain: getQueryValue('domain'),
            origin: origin,
        },
        extras
    )
}

const getPageNameAndProperties = (path = null) => {
    const finalPath = path !== null ? path : window.location.pathname
    switch (finalPath) {
        case '/':
            return { name: 'Home', properties: {} }
        case '/model':
            return { name: 'Model Choice', properties: {} }
        case '/product':
            return { name: 'Item Detail', properties: {} }
        case '/favorites':
            const state = store.getState()
            return {
                name: 'Favorite',
                properties: { favorite_type: state.favorites.isOutfit ? 'outfit' : 'item' },
            }
        case '/cart':
            return { name: 'Outfit Detail', properties: {} }
        case '/swipe':
            return { name: 'Swipe', properties: {} }
        case '/catalog':
            return { name: 'Catalog', properties: {} }
    }
    return { name: 'Unknown', properties: {} }
}

export const getPageName = (path = null) => {
    return getPageNameAndProperties(path).name
}

const doTrackPage = async () => {
    // ---- Ajout des basics ----
    const pageAndProperties = getPageNameAndProperties()
    const finalProperties = Object.assign(getBasicProperties(), pageAndProperties.properties)

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackPage', pageAndProperties.name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (enableAnalytics) {
        if (areAnalyticsLoaded) {
            track(`Viewed ${pageAndProperties.name} Page`, finalProperties)
        }
        if (areGoogleAnalyticsLoaded) {
            ReactGA.pageview(window.location.pathname, pageAndProperties.name)
        }
    }
}

const addPrefixToProperties = (prefix, properties: any) => {
    const prefixed = {}
    for (const key in properties) {
        prefixed[prefix + key] = properties[key]
    }
    return prefixed
}

const convertProperties = (properties?: any) => {
    // ---- Traduction des properties ----
    let convertedProperties = {}
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                convertedProperties = Object.assign(convertedProperties, convertProperties(one))
            }
        } else if (typeof properties.garment_id !== 'undefined') {
            convertedProperties = {
                item_internal_id: properties.garment_id,
                item_external_id: properties.product_external_id || null,
                item_name: properties.product_name,
                item_type: properties.garment_type,
                item_price: properties.product_price,
            }
        } else if (typeof properties.model_id !== 'undefined') {
            convertedProperties = {
                model_id: properties.model_id,
                model_name: properties.model_name,
                model_height: properties.model_height,
                model_garment_size: properties.model_garment_size,
            }
        } else if (typeof properties.look_id !== 'undefined') {
            const state = store.getState()
            const garmentTypes = state.profile.company.garment_types || [
                'TOP',
                'BOTTOM',
                'DRESS',
                'OUTERWEAR',
            ]
            let nbItems = 0
            let totalPrice = 0
            const types = []
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    nbItems += 1
                    totalPrice += properties[typeLower].product_price
                    types.push(type)
                }
            }
            convertedProperties = Object.assign(
                {
                    outfit_id: properties.look_id,
                    outfit_nb_items: nbItems,
                    outfit_total_price: totalPrice,
                    outfit_types: types.join(','),
                },
                convertProperties(properties.model)
            )
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    convertedProperties = Object.assign(
                        convertedProperties,
                        addPrefixToProperties(
                            typeLower + '_',
                            convertProperties(properties[typeLower])
                        )
                    )
                }
            }
        } else if (typeof properties === 'object') {
            convertedProperties = properties
        }
    }
    return convertedProperties
}

const convertFinalProperties = (name: string, properties: Record<string, any>) => {
    // ---- Surcharge globale si besoin ----
    if (name === 'Item Added to cart') {
        return Object.assign(properties, {
            revenue: properties.item_price,
            price: properties.item_price,
            currency: 'EUR',
            // ---- Old segment fields ----
            $revenue: properties.item_price,
            $price: properties.item_price,
            $quantity: 1,
        })
    }

    return properties
}

const postInitEventWhenDeviceAndSession = (
    type: string,
    finalProperties: Record<string, any>,
    n = 0
) => {
    const deviceId = getDeviceId()
    const sessionId = getSessionId()
    if (deviceId !== undefined && sessionId !== undefined) {
        PostInitEvent(Object.assign({ type, deviceId, sessionId }, finalProperties))
    } else if (n < 10) {
        setTimeout(() => {
            postInitEventWhenDeviceAndSession(type, finalProperties, n + 1)
        }, 500)
    }
}

const doTrackEvent = async (name: string, properties?: any, category: string = null) => {
    // ---- Traduction des properties ----
    const convertedProperties = convertProperties(properties)
    const pageAndProperties = getPageNameAndProperties()
    const convertedPageProperties = Object.assign(
        { page_view: pageAndProperties.name },
        pageAndProperties.properties
    )
    const convertedCategory = { category: category }

    // ---- Ajout des basics ----
    const finalProperties = convertFinalProperties(
        name,
        Object.assign(
            convertedCategory,
            getBasicProperties(),
            convertedPageProperties,
            convertedProperties
        )
    )

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackEvent', name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (enableAnalytics) {
        if (areAnalyticsLoaded) {
            track(name, finalProperties)
        }
        if (areGoogleAnalyticsLoaded) {
            ReactGA.event({
                category: finalProperties.category,
                action: name,
            })
        }
        if (store.getState().profile.company.enable_event) {
            switch (name) {
                case 'Init':
                    postInitEventWhenDeviceAndSession('INIT', finalProperties)
                    break
                case 'Item Added to cart':
                    postInitEventWhenDeviceAndSession('ADD_TO_CART', finalProperties)
                    break
            }
        }
        if (inIframe() && finalProperties.client_internal.match(/^laredoute/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                [
                    'Init',
                    'Outfit Created',
                    'Item Selected',
                    'Model Selected',
                    'Item Added to cart',
                    'Item Saved to favorite',
                ].indexOf(name) !== -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `window.dispatchEvent(new CustomEvent('veesual_event:${name.replace(
                            /'/,
                            "\\'"
                        )}', {detail: JSON.parse('${JSON.stringify(finalProperties).replace(
                            /'/,
                            "\\'"
                        )}')}));`,
                    },
                ])
            }
        }
        if (inIframe() && finalProperties.client_internal.match(/^gemo/)) {
            // ---- En fonction de l'event ----
            let eventName = null,
                eventData = null
            switch (name) {
                case 'Init':
                    eventName = 'page.display'
                    eventData = {
                        page: 'veesual_experience',
                        x6: 'veesual_experience',
                    }
                    break
                case 'Outfit Created':
                    eventName = 'veesual.outfit_created'
                    eventData = {
                        outfit_nb_items: finalProperties.outfit_nb_items,
                        outfit_total_price: finalProperties.outfit_total_price,
                        outfit_types: finalProperties.outfit_types,
                    }
                    break
                case 'Item Selected':
                    eventName = 'veesual.item_selected'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
                case 'Model Selected':
                    eventName = 'veesual.model_selected'
                    eventData = {
                        model_name: finalProperties.model_name,
                        model_height: finalProperties.model_height,
                        model_garment_size: finalProperties.model_garment_size,
                    }
                    break
                case 'Item Added to cart':
                    eventName = 'veesual.add_to_cart'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_size_selected_label: finalProperties.item_size_selected_label,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
            }
            if (eventName !== null && eventData !== null) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `pa.sendEvent('${eventName.replace(
                            /'/,
                            "\\'"
                        )}', JSON.parse('${JSON.stringify(eventData).replace(/'/, "\\'")}'));`,
                    },
                ])
            }
        }
        if (inIframe() && finalProperties.client_internal.match(/^jules/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                [
                    'Init',
                    'Outfit Created',
                    'Item Selected',
                    'Model Selected',
                    'Item Added to cart',
                ].indexOf(name) !== -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'event',
                        partner: ['jules', 'contentsquare'],
                        payload: {
                            name: name,
                            properties: finalProperties,
                            timestamp: Date.now(),
                        },
                    },
                ])
            }
        }
        if (inIframe() && finalProperties.client_internal.match(/^(sandro|gemo)/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                [
                    'Init',
                    'Outfit Created',
                    'Item Selected',
                    'Model Selected',
                    'Item Added to cart',
                ].indexOf(name) !== -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'event',
                        partner: ['contentsquare'],
                        payload: {
                            name: name,
                            properties: finalProperties,
                            timestamp: Date.now(),
                        },
                    },
                ])
            }
        }
    }
}

const canUseAnalytics = () => {
    if (hasAnalytics() || hasGoogleAnalytics()) {
        if (!areAnalyticsLoaded && !areGoogleAnalyticsLoaded) {
            initTracking()
            doTrackEvent('Init', null, 'Experience')
        }
        return true
    }
    return false
}

export const trackPage = async () => {
    if (canUseAnalytics()) {
        doTrackPage()
    }
}

const eventQueue = []
export const trackEvent = async (name: string, properties?: any, category: string = null) => {
    if (store.getState().profile.company) {
        if (canUseAnalytics()) {
            while (eventQueue.length) {
                const eventData = eventQueue.shift()
                doTrackEvent(eventData.name, eventData.properties, eventData.category)
            }
            doTrackEvent(name, properties, category)
        }
    } else {
        eventQueue.push({ name, properties, category })
    }
}
