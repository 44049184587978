import React, { useMemo, useState } from 'react'
import useCustomTranslation from 'src/utils/translation'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { resizeImage } from 'src/utils/image'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'

import { InfoCircleOutlined, ZoomInOutlined } from '@ant-design/icons'
import { getFavoriteIcon, getSyncIcon } from 'src/utils/icon'
import { useDispatch, useSelector } from 'react-redux'
import { isLookInFavorites } from 'src/utils/favorite'
import { HandleLookRequest, SetUserTuckAction } from 'src/store/actions/look'

import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { Button, Card, Checkbox, Row, Tooltip } from 'antd'

interface CardModelProps {
    look: Models.Look
    lookLoading: boolean
    imageUrls: string[]
    index: number
    ratio: number
    favorites: Models.Look[]
    addOnFavorites(value: Models.Look): void
    removeFromFavorites(item: Models.Look): void
    handleBackClick?(index: number, e: React.MouseEvent): void
    maxWidthStyle?: number
}

const CardModel: React.FunctionComponent<CardModelProps> = (props) => {
    const { t } = useCustomTranslation()
    const { lookHasDressType } = useTypeFunctions()
    const dispatch = useDispatch()
    const {
        look,
        lookLoading,
        imageUrls,
        index,
        favorites,
        addOnFavorites,
        removeFromFavorites,
        ratio,
        handleBackClick,
        maxWidthStyle,
    } = props
    const company = useSelector((state: State.Root) => state.profile?.company)
    const inFavorites = isLookInFavorites(favorites, look)
    const [visible, setVisible] = useState(false)

    const domain = getQueryValue('domain')
    const withInfo = !domain || domain !== 'marinehenrion.com'

    const tuckDisabled = useMemo(() => {
        return (
            !look || !company || lookHasDressType(look) || look.mode_switch === false || lookLoading
        )
    }, [look, lookLoading, company, lookHasDressType])

    // ---- array of slides containing image data used by the lightbox ----
    const imagesSlides = useMemo(() => {
        return Array.from(imageUrls, (imageUrl) => {
            return { src: imageUrl }
        })
    }, [imageUrls])

    const handleCardClick = () => {
        trackEvent('Outfit Clicked', [look, { outfit_index_selected: index }], 'Outfit')
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Outfit Saved to favorite', [look, { outfit_index_selected: index }], 'Outfit')
        addOnFavorites(look)
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        trackEvent(
            'Outfit Removed from favorite',
            [look, { outfit_index_selected: index }],
            'Outfit'
        )
        removeFromFavorites(look)
    }

    const handleZoomClick = (e) => {
        e.stopPropagation()
        trackEvent('Zoom Opened', [look, { outfit_index_selected: index }], 'Outfit')
        setVisible(true)
    }

    const handlePoweredClick = () => {
        trackEvent('Powered Clicked', [look, { outfit_index_selected: index }], 'Outfit')
    }

    const handleChangeTuck = (e) => {
        const newTuckValue = e.target.checked ? 'tuck' : 'untuck'
        trackEvent('Tuck in Changed', [look, { tuck_value: newTuckValue }], 'Outfit')
        dispatch(
            HandleLookRequest({
                lookRequest: { mode: newTuckValue },
                keepAdditional: true,
            })
        )

        // ---- We store the value chosen by the user to keep as default for look requests ----
        dispatch(SetUserTuckAction(newTuckValue))
    }

    const utms = `utm_source=client_experience&utm_medium=powered_by&utm_campaign=${company.internal}`

    return (
        <Card
            className='card-model card-model--container override_img_container'
            onClick={handleCardClick}
            cover={
                <div className='card-model--image-container'>
                    <div className='card-model--image'>
                        <ImageSmooth
                            className='card-model--background'
                            src={imageUrls ? resizeImage(imageUrls[index], { width: 800 }) : 'none'}
                            ratio={ratio}
                            lazyload={false}
                            loader={true}
                            loading={lookLoading}
                            cover={true}
                        />
                    </div>
                </div>
            }
        >
            {look && (
                <>
                    <div className='card-model--bottom'>
                        <Row className='card-model--bottom-left'>
                            {look.image_urls.length > 1 && handleBackClick ? (
                                <>
                                    <div
                                        className={`button--icon-left`}
                                        onClick={() => handleBackClick(index === 0 ? 1 : 0, null)}
                                    >
                                        <Button
                                            type='link'
                                            icon={getSyncIcon()}
                                            className='button--icon-left'
                                        />
                                    </div>
                                </>
                            ) : (
                                <span className='empty'></span>
                            )}
                            <Button
                                icon={<ZoomInOutlined />}
                                type='link'
                                className='button--icon-left'
                                onClick={handleZoomClick}
                            />
                        </Row>
                        {company.enable_tuck && (
                            <div
                                className='card-model--bottom-right card-model--checkbox-container'
                                style={maxWidthStyle && { maxWidth: maxWidthStyle / 2 }}
                            >
                                <span
                                    className={`card-model--checkbox-label ${
                                        tuckDisabled ? 'card-model--checkbox-label-disabled' : ''
                                    }`}
                                >
                                    {t('look.tuck_in')}
                                </span>
                                <Checkbox
                                    checked={look.mode === 'tuck'}
                                    onChange={handleChangeTuck}
                                    disabled={tuckDisabled}
                                />
                            </div>
                        )}
                    </div>
                    <div className='card-model--top'>
                        <Tooltip
                            trigger={['hover']}
                            placement='left'
                            overlayClassName='card-model card-model--tooltip'
                            title={t('look.favorite_info')}
                        >
                            {!inFavorites ? (
                                <Button
                                    onClick={handleAddToFavorite}
                                    icon={getFavoriteIcon('outlined')}
                                    type='link'
                                    className='button button--icon-right'
                                />
                            ) : (
                                <Button
                                    icon={getFavoriteIcon('filled')}
                                    type='link'
                                    className='button button--icon-right'
                                    onClick={handleRemoveFromFavorite}
                                />
                            )}
                        </Tooltip>
                        {withInfo && (
                            <Tooltip
                                trigger={['click', 'hover']}
                                placement='left'
                                overlayClassName='card-model card-model--tooltip'
                                title={
                                    <div style={{ textAlign: 'left' }}>
                                        {look.model.model_description ||
                                            t('model.description', {
                                                modelName:
                                                    look.model.model_display ||
                                                    look.model.model_name,
                                                modelHeight: `${Math.floor(
                                                    parseInt(look.model.model_height) / 100
                                                )}m${parseInt(look.model.model_height) % 100}`,
                                                modelSize: look.model.model_garment_size,
                                            })}
                                        <br />
                                        <br />
                                        {t('look.info')}
                                        <br />
                                        <br />
                                        {t('look.powered_by')}{' '}
                                        <a
                                            href={`https://www.veesual.ai?${utms}`}
                                            onClick={handlePoweredClick}
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            Veesual
                                        </a>
                                        {t('look.powered_by_after')}
                                    </div>
                                }
                            >
                                <Button
                                    icon={<InfoCircleOutlined />}
                                    type='link'
                                    className='button button--icon-right'
                                ></Button>
                            </Tooltip>
                        )}
                    </div>
                    <Lightbox
                        open={visible}
                        close={() => setVisible(false)}
                        slides={imagesSlides}
                        index={index}
                        carousel={{ finite: imagesSlides.length === 1 }}
                        render={
                            imagesSlides.length === 1 && {
                                buttonNext: () => null,
                                buttonPrev: () => null,
                            }
                        }
                        styles={{ container: { backgroundColor: '#000A' } }}
                        plugins={[Zoom as Plugin]}
                    />
                </>
            )}
        </Card>
    )
}

export default CardModel
