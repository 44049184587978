import React from 'react'
import { useSelector } from 'react-redux'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'

interface PoweredByProps {
    mobile?: boolean
    overrideClassName?: string
}

export default function PoweredBy(props: PoweredByProps) {
    const { t } = useCustomTranslation()
    const clientInternal = useSelector((state: State.Root) => state.profile?.company.internal)
    const { mobile, overrideClassName } = props

    const handlePoweredClick = () => {
        trackEvent('Powered Clicked', {}, 'Bottom')
    }

    const utms = `utm_source=client_experience&utm_medium=powered_by&utm_campaign=${clientInternal}`

    return (
        <div
            className={`${mobile ? 'layout--poweredby-mobile' : 'layout--poweredby-desktop'} ${
                overrideClassName ? overrideClassName : ''
            }`}
        >
            {t('look.powered_by')}{' '}
            <a
                href={`https://www.veesual.ai?${utms}`}
                onClick={handlePoweredClick}
                target='_blank'
                rel='noreferrer'
            >
                Veesual
            </a>
            {t('look.powered_by_after')}
        </div>
    )
}
