import React from 'react'
import ImageSmooth from '../image/ImageSmooth'
import { Checkbox } from 'antd'

interface StyleBarTileProps {
    image: string
    title: string
    overrideClassName?: string
    width?: number
    onClick?: (e: React.MouseEvent) => void
    onIconClick?: (e: React.MouseEvent) => void
    hidden?: boolean
    removable?: boolean
    isActive?: boolean
    isZoomedTile?: boolean
    noIcon?: boolean
}

export default function StyleBarTile(props: StyleBarTileProps) {
    const {
        image,
        title,
        overrideClassName,
        width,
        onClick,
        hidden,
        removable,
        onIconClick,
        isActive,
        isZoomedTile,
        noIcon,
    } = props
    return (
        <div
            onClick={onClick ? onClick : undefined}
            className={`style-bar--element ${overrideClassName ? overrideClassName : ''}`}
            style={{
                width,
            }}
        >
            <div
                style={isZoomedTile ? { width: width * 2, marginBottom: -width } : { width }}
                className='style-bar--image-container'
            >
                <ImageSmooth src={image} ratio={100} lazyload={false} overflow={false} />
            </div>

            <div
                className={`style-bar--icon-container ${
                    hidden ? 'style-bar--icon-container--hidden' : ''
                }`}
            >
                {!noIcon && (
                    <div
                        onClick={!hidden ? onIconClick : undefined}
                        className='style-bar--touch-div'
                    >
                        <Checkbox
                            disabled={!removable && !hidden}
                            checked={!hidden}
                            className='style-bar--checkbox'
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                pointerEvents: !removable && !hidden ? 'none' : 'auto',
                            }}
                        />
                    </div>
                )}
            </div>
            <div className='style-bar--title' style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
                {title}
            </div>
        </div>
    )
}
