import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { SetLookIndexAction } from '../../store/actions/look'
import { AddFavoritesOutfitAction, RemoveFavoritesOutfitAction } from 'src/store/actions/favorites'
import { trackEvent } from 'src/utils/tracking'

import Loader from 'src/components/Loader'
import CardModel from 'src/components/card/CardModel'
import { getUserModel, saveUserModel } from 'src/utils/localStorageHelpers'
import { useLocation } from 'react-router-dom'
import { Button, Col, Row } from 'antd'
import useCustomTranslation from 'src/utils/translation'
import useCustomHistory from 'src/utils/custom-history-hook'

interface LookContainerProps {
    swipeStyle?: boolean
    contentMaxWidth?: number
    hasEnoughWidth?: boolean
}

const LookContainer: React.FunctionComponent<LookContainerProps> = (props) => {
    const customHistory = useCustomHistory()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { pathname } = useLocation()
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookLoading = useSelector((state: State.Root) => state.look?.loading)
    const lookIndex = useSelector((state: State.Root) => state.look?.index)
    const favorites = useSelector((state: State.Root) => state.favorites).outfits
    const company = useSelector((state: State.Root) => state.profile?.company)
    const { swipeStyle, contentMaxWidth, hasEnoughWidth } = props

    const maxWidthStyle = contentMaxWidth && { maxWidth: contentMaxWidth }

    const [storageModel, setStorageModel] = useState<{ modelId: string; identityId: string }>()

    // Check if we need to scroll to the Details
    useEffect(() => {
        const scrollElement = document.getElementById('layoutScrollableContent')
        if (history.state?.state?.toDetail === true) {
            // Need this to clear the history state without rerendering the page. Otherwise toDetail stays true
            window.history.replaceState({}, document.title)

            const localContainerElem = document.getElementById('lookContainerId')
            // We add 40 because of the padding above the lookContainer and the padding on top of the cart
            return scrollElement.scrollTo({
                top: localContainerElem.clientHeight + 40,
                behavior: 'smooth',
            })
        } else {
            return scrollElement.scrollTo({ top: 1 })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ---- Check UserModel when we change page because on catalog mode we keep the Look container shown ----
    useEffect(() => {
        if (!storageModel) {
            // ---- Load local storage model info ----
            const localStorageModel = getUserModel()
            setStorageModel(localStorageModel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const lookImgRatio = 100 / (company?.look_image_ratio || 0.66)

    const addOnFavorites = (current: Models.Look) => {
        dispatch(AddFavoritesOutfitAction([...favorites, current] as any))
    }

    const removeOnFavorites = (current: Models.Look) => {
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.look_id === current.look_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        dispatch(RemoveFavoritesOutfitAction(dataList))
    }

    const handleChangeModelClick = () => {
        trackEvent('Choose model Clicked', look, 'Outfit')
        customHistory.push('/model')

        // ---- Add current model in local storage if it doesn't exist ----
        if (!storageModel) {
            saveUserModel(look.model)
        }
    }

    const handleBackClick = (index: number, e?: React.MouseEvent) => {
        e?.stopPropagation()
        trackEvent(
            'Back Changed',
            [look, { back_value: index === 1 ? 'on' : 'off', back_type: 'toggle' }],
            'Outfit'
        )
        dispatch(SetLookIndexAction(index))
    }

    const handleSeeLookDetailClick = () => {
        trackEvent('Outfit Detailed', look, 'Outfit')
        const localContainerElem = document.getElementById('lookContainerId')
        const scrollElement = document.getElementById('layoutScrollableContent')
        // If we are on the mobile we use the window scroll and offset it
        if (window.innerWidth < 768) {
            // We add 40 because of the padding above the lookContainer and the padding on top of the cart
            return scrollElement.scrollTo({
                top: localContainerElem.clientHeight + 40,
                behavior: 'smooth',
            })
        }

        // On desktop we use the right container
        const rightContainerElem = document.getElementById('layoutRightContentId')

        return rightContainerElem.scrollTo({
            top: localContainerElem.clientHeight,
            behavior: 'smooth',
        })
    }

    return (
        <Row
            id='lookContainerId'
            className={'look look--container' + (swipeStyle ? ' look--container-swipe' : '')}
        >
            <Row
                className={'look--content ' + (swipeStyle ? 'look--swipe-content' : '')}
                style={maxWidthStyle}
            >
                <div className='look--model-button-container'>
                    {!storageModel ? (
                        <Button
                            onClick={handleChangeModelClick}
                            className={`button--change-model-first-time ${
                                hasEnoughWidth ? 'button--change-model-left-first-time' : ''
                            }`}
                        >
                            {t('look.change_model')}
                        </Button>
                    ) : (
                        <div
                            onClick={handleChangeModelClick}
                            className={`button--underlined button--change-model ${
                                hasEnoughWidth ? 'button--change-model-left' : ''
                            }`}
                        >
                            {t('look.change_model')}
                        </div>
                    )}
                </div>
                <Col span={24} id='lookCardContainerId'>
                    {look ? (
                        <>
                            <CardModel
                                look={look}
                                lookLoading={lookLoading !== 0}
                                imageUrls={look.image_urls}
                                index={lookIndex}
                                ratio={lookImgRatio}
                                favorites={favorites}
                                addOnFavorites={addOnFavorites}
                                removeFromFavorites={removeOnFavorites}
                                handleBackClick={handleBackClick}
                                maxWidthStyle={contentMaxWidth}
                            />
                            {!swipeStyle && (
                                <Button
                                    className={
                                        'button button--detail' +
                                        (swipeStyle ? ' look--button-swipe' : '')
                                    }
                                    type='primary'
                                    onClick={handleSeeLookDetailClick}
                                >
                                    {t('look.see_look_detail')}
                                </Button>
                            )}
                        </>
                    ) : (
                        <div style={{ paddingBottom: `calc(${lookImgRatio}% - 38px)` }}>
                            <Loader />
                        </div>
                    )}
                </Col>
            </Row>
        </Row>
    )
}

export default LookContainer
