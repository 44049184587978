import React from 'react'

import Icon from '@ant-design/icons'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { ReactComponent as BookmarkOutlined } from '../static/icons/bookmark.svg'
import { ReactComponent as BookmarkFilled } from '../static/icons/bookmark-solid.svg'
import { ReactComponent as FilterSvg } from '../static/icons/sliders-solid.svg'
import { ReactComponent as SyncSvg } from '../static/icons/sync.svg'
import { ReactComponent as SwipeSvg } from '../static/icons/swipe.svg'
import store from 'src/store'

export const getFavoriteIcon = (mode = 'outlined') => {
    const internal = store.getState().profile?.company?.internal

    switch (mode) {
        case 'outlined':
            if (internal && internal.match(/^laredoute/)) {
                return <Icon component={BookmarkOutlined} />
            }
            return <HeartOutlined />
        case 'filled':
            if (internal && internal.match(/^laredoute/)) {
                return <Icon component={BookmarkFilled} />
            }
            return <HeartFilled />
    }
}

export const getSwipeIcon = () => {
    return (
        <Icon component={SwipeSvg} style={{ fontSize: 24 }} className='button--switch-view-swipe' />
    )
}

export const getFilterIcon = () => {
    return <Icon component={FilterSvg} style={{ fontSize: 24 }} />
}

export const getSyncIcon = () => {
    return <Icon component={SyncSvg} />
}
