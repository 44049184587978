import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import { HandleLookRequest } from 'src/store/actions/look'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking'
import ImageSmooth from '../image/ImageSmooth'
import useCustomHistory from 'src/utils/custom-history-hook'
import { useAppSelector } from 'src/store'
import { saveUserModel } from 'src/utils/localStorageHelpers'
import { Button, Card, Row, Tooltip, Typography } from 'antd'

const { Title, Paragraph } = Typography

interface CardCarouselProps {
    id: number
    current: number
    model: Models.ModelProduct
    ratio: number
    cardWidth?: number
    hide: boolean
}

let checkForDragCardCarousel

const CardCarousel: React.FunctionComponent<CardCarouselProps> = (props) => {
    const dispatch = useDispatch()
    const customHistory = useCustomHistory()
    const { t } = useCustomTranslation()
    const { id, current, model, ratio, cardWidth, hide } = props

    const lookRequest = useAppSelector((state) => state.look.request)

    const [truncated, setTruncated] = useState<boolean>(false)

    const description = useMemo(() => {
        return (
            model.model_description ||
            t('model.description', {
                modelName: model.model_display || model.model_name,
                modelHeight: `${Math.floor(parseInt(model.model_height) / 100)}m${
                    parseInt(model.model_height) % 100
                }`,
                modelSize: model.model_garment_size,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model])

    const handleSelectClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        trackEvent('Model Selected', model, 'Model Choice')
        dispatch(
            HandleLookRequest({ lookRequest: { ...lookRequest, model: model }, focus: 'MODEL' })
        )

        // ---- Save model in local storage ----
        saveUserModel(model)

        return customHistory.goBack()
    }

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        trackEvent(
            'Model card Clicked',
            [model, { model_card_current: id == current }],
            'Model Choice'
        )
        if (id === current) {
            handleSelectClick(e)
        }
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    if (hide) {
        return null
    }

    return (
        <Card
            className='card-carousel card-carousel--container override_card_container'
            hoverable
            cover={
                <div
                    className='card card--image-container'
                    onMouseDown={mouseDownCoords}
                    onMouseUp={(e) => handleCardClick(e)}
                >
                    <div className={'card--image'}>
                        <ImageSmooth
                            overflow={false}
                            ratio={ratio}
                            src={resizeImage(model.image_url, { width: 800 })}
                            loader={true}
                            cover={true}
                        />
                    </div>
                </div>
            }
            style={cardWidth && { width: cardWidth }}
        >
            <Title
                ellipsis={{
                    rows: 1,
                }}
                className='card-carousel--title'
            >
                {model.model_display || model.model_name}
            </Title>
            <Tooltip title={truncated && description} trigger={['hover', 'click']}>
                <Paragraph
                    ellipsis={{
                        rows: 3,
                        onEllipsis: setTruncated,
                    }}
                    className='card-carousel--text'
                >
                    {description}
                </Paragraph>
            </Tooltip>

            <Row align='middle' justify='center'>
                <Button
                    className='card-carousel--button'
                    type='primary'
                    onClick={handleSelectClick}
                >
                    {t('model.select')}
                </Button>
            </Row>
        </Card>
    )
}

export default CardCarousel
