import { Tabs } from 'antd'
import React, { useMemo } from 'react'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'

interface TypeTabsProps {
    onTabClick?: (key: string) => void
    activeType: string
}

export default function TypeTabs(props: TypeTabsProps) {
    const { t } = useCustomTranslation()

    const company = useAppSelector((state) => state.profile.company)
    const menuArray = useMemo(() => {
        const tmpArray = []
        company.garment_types.forEach((localGarmentType) => {
            tmpArray.push({
                label: t(`layout.${localGarmentType.toLowerCase()}`),
                value: localGarmentType,
            })
        })

        return tmpArray
    }, [company.garment_types, t])

    const MenuOption = menuArray
        ? menuArray
        : [
              { label: t('layout.top'), value: 'TOP' },
              { label: t('layout.bottom'), value: 'BOTTOM' },
              { label: t('layout.dress'), value: 'DRESS' },
              { label: t('layout.outerwear'), value: 'OUTERWEAR' },
              { label: t('layout.other'), value: 'OTHER' },
          ]

    const { onTabClick, activeType } = props
    return (
        <Tabs
            onTabClick={(key) => {
                onTabClick && onTabClick(key)
            }}
            activeKey={activeType}
            className={`menu menu--container`}
            items={
                MenuOption &&
                MenuOption.map((item: { label: string; value: string }) => {
                    if (company && company?.garment_types?.indexOf(item.value) !== -1) {
                        return {
                            label: item.label,
                            key: item.value,
                        }
                    }
                })
            }
        />
    )
}
