import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import { CloseOutlined } from '@ant-design/icons'

import FilterSelect from '../../components/select/filter'
import { optionsColor, optionsSkinColor, optionsGender } from 'src/utils/data/js/options'
import CollapseSlider from 'src/components/collapse/CollapseSlider'
import { ChangeFiltersModelAction } from 'src/store/actions/filters'
import { handleSidebarAction } from 'src/store/actions/sidebar'
import useCustomHistory from 'src/utils/custom-history-hook'
import PoweredBy from 'src/components/PoweredBy'
import { Button, Row } from 'antd'

interface FiltersModelProps {
    mobile: boolean
}

const FiltersModel: React.FunctionComponent<FiltersModelProps> = (props) => {
    const customHistory = useCustomHistory()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const filters = useSelector((state: State.Root) => state.filters)

    const onChange = async (value: any, name: string) => {
        const newData: any = { ...filters.model }
        newData[name] = value
        await dispatch(ChangeFiltersModelAction(newData))
    }

    const onClose = () => {
        return customHistory.goBack()
    }

    return (
        <Row justify='center' className='filters-model filters-model--container'>
            <div>
                <Row className='filters-model--head'>
                    <h2 className='filters-model--title'>{t('filter.title_model')}</h2>
                    {props.mobile ? (
                        <CloseOutlined onClick={() => dispatch(handleSidebarAction())} />
                    ) : (
                        <Button
                            onClick={onClose}
                            ghost
                            className='button card--light filters-model--button'
                        >
                            {t('filter.close')}
                        </Button>
                    )}
                </Row>
                <Row className='filters-model--content'>
                    <FilterSelect
                        placeholder={t('filter.fake_gender')}
                        onChange={onChange}
                        name='gender'
                        options={optionsGender}
                    />
                    <CollapseSlider />
                    <FilterSelect
                        placeholder={t('filter.fake_hair_color')}
                        onChange={onChange}
                        name='hairColor'
                        options={optionsColor}
                    />
                    <FilterSelect
                        placeholder={t('filter.fake_skin_color')}
                        onChange={onChange}
                        name='skinColor'
                        options={optionsSkinColor}
                    />
                </Row>
            </div>
            <PoweredBy />
        </Row>
    )
}

export default FiltersModel
