import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import LookContainer from '../look/Look'

import { FetchTypeGarmentAction } from '../../store/actions/garment'

import FiltersModel from '../filters/FiltersModel'
import FilterResponsive from '../filters/FilterResponsive'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import CartPage from 'src/pages/cart'
import useCustomHistory from 'src/utils/custom-history-hook'
import { calcMaxWidth } from 'src/utils/calc-max-width'
import LookTracking from '../look/LookTracking'
import { sendIframeMessage } from 'src/utils/iframe'
import { SetParentHeightAction } from 'src/store/actions/profile'
import useCustomSize from 'src/utils/size'
import PoweredBy from 'src/components/PoweredBy'
import ModalAddedCart from 'src/components/ModalAddedCart'
import { handleModalAction } from 'src/store/actions/modal'
import useShowHideHeader from 'src/utils/showHideHeader'
import { useAppSelector } from 'src/store'
import {
    addFilterForType,
    getAllGarments,
    getCurrentActiveFiltersForType,
    getCurrentFiltersForType,
    getCurrentPageForType,
    resetAllFilterForType,
} from 'src/store/slices/databaseSlice'
import Loader from 'src/components/Loader'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import { FetchRecommendationsAction, HandleLookRequest } from 'src/store/actions/look'
import DrawerContent from 'src/components/DrawerContent'
import HeaderMainContent from 'src/components/header/HeaderMainContent'
import LocalFiltersContext from 'src/components/LocalFiltersContext'
import ExitButton from 'src/components/button/ExitButton'
import ToggleViewButtons from 'src/components/button/ToggleViewButtons'
import { ArrowLeftOutlined } from '@ant-design/icons'
import StyleBar from 'src/components/stylebar/StyleBar'
import { STYLEBAR_HEIGHT_DESKTOP, STYLEBAR_HEIGHT_MOBILE } from 'src/settings/global'
import { getPrimaryTypesForLook } from 'src/utils/typeMethods-hook'
import { Button, Col, Drawer, Layout, Modal } from 'antd'

const { Header, Content } = Layout

interface LayoutProps {
    children: React.ReactNode
}

const LayoutContainer: React.FunctionComponent<LayoutProps> = (props) => {
    const location = useLocation()
    const customHistory = useCustomHistory()
    const size = useCustomSize()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const showHideHeaderScrollListener = useShowHideHeader()
    const company = useSelector((state: State.Root) => state.profile?.company)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)
    const modalVisible = useSelector((state: State.Root) => state.modal.open)
    const showFilters = useAppSelector((state) => state.filters.showFilters)
    const lookRecommendations = useSelector((state: State.Root) => state.look?.recommendations)
    const garmentFacets = useAppSelector((state) => getCurrentFiltersForType(state, garmentType))
    const currentPage = useAppSelector((state) => getCurrentPageForType(state, garmentType))
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const currentGarment = useAppSelector((state) =>
        state.look.request ? state.look.request[garmentType.toLowerCase()] : null
    )
    const lookRequest = useAppSelector((state) => state.look.request)
    const [getGarmentsTrigger, { data: garmentData, isLoading, error }] = useCustomGetGarments()

    const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)
    const [contentActualWidth, setContentACtualWidth] = useState<number>(null)

    // Local filters used for multiselect
    const [localFilters, setLocalFilters] = useState<{ [key: string]: Models.Facet[] } | null>(null)
    const [lastFacetModified, setLastFacetModified] = useState<string | null>(null)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    // Use to know when we highlight a subcategory or not
    const onlyOneSubCategory = useMemo(() => {
        return (
            activeFilters &&
            company.garment_category_facets &&
            activeFilters[company.garment_category_facets[garmentType]]?.length === 1 &&
            Object.keys(activeFilters).length === 1
        )
    }, [activeFilters, garmentType, company.garment_category_facets])

    const [open, setOpen] = useState(false)

    useEffect(() => {
        const updateIframeHeight = (e) => {
            if (typeof e.data !== 'string') {
                return
            }
            const splitted = e.data.split(':')
            const messageName = splitted[0]
            if (messageName === 'veesual_parentsize') {
                const data = JSON.parse(splitted.slice(1).join(':'))
                if (data.height && Number(data.height) !== Number(parentHeight)) {
                    dispatch(SetParentHeightAction(data.height))
                }
            }
        }
        window.addEventListener('message', updateIframeHeight, false)
        sendIframeMessage('veesual_askparentsize', null)
        return () => {
            window.removeEventListener('message', updateIframeHeight)
        }
        // eslint-disable-next-line
    }, [parentHeight])

    useEffect(() => {
        const elementToListen = [
            document,
            document.getElementById('layoutScrollableContent'),
            document.getElementById('layoutRightContentId'),
            // Attention, ces elements doivent aussi etre present dans showHideHeader.ts
        ]

        // ---- Branchements des listeners ----
        for (const element of elementToListen) {
            if (element) {
                element.addEventListener('scroll', showHideHeaderScrollListener)
            }
        }
        return () => {
            for (const element of elementToListen) {
                if (element) {
                    element.removeEventListener('scroll', showHideHeaderScrollListener)
                }
            }
        }
        // eslint-disable-next-line
    }, [])

    // Updates the contextMaxWidth with the size of the container and listen to resizes
    const contentMargin = 20
    useEffect(() => {
        function handleResize() {
            const content = document.getElementById('layoutRightContentId')
            if (content) {
                const tmpStyle = size.getLayoutScrollableContentStyle('height')
                const newContentMaxWidth = calcMaxWidth(
                    window.innerWidth / 2,
                    window.innerWidth / 4,
                    tmpStyle.height || content.clientHeight,
                    lookRatio,
                    // offset = 40 button detail + stylebar
                    40 +
                        (window.innerWidth >= 768
                            ? STYLEBAR_HEIGHT_DESKTOP
                            : STYLEBAR_HEIGHT_MOBILE),
                    contentMargin,
                    contentMargin
                )
                setContentMaxWidth(newContentMaxWidth)
                setContentACtualWidth(
                    newContentMaxWidth > window.innerWidth / 3 &&
                        newContentMaxWidth <= window.innerWidth / 2
                        ? newContentMaxWidth
                        : null
                )
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [lookRatio, parentHeight, location.pathname])

    // We update the local filters when it's empty and we get new facets
    useEffect(() => {
        if (garmentFacets !== undefined) {
            if (!localFilters || !localFilters[garmentType]) {
                setLocalFilters({ ...localFilters, [garmentType]: garmentFacets })
            }

            // We update the count values to existing localFilters
            if (localFilters && localFilters[garmentType]) {
                // Deepcopy of the localFilters to handle the object
                const cloneLocalFilters = JSON.parse(JSON.stringify(localFilters))
                const activeFiltersKeys = Object.keys(activeFilters)

                // Loop on new facetkeys
                Object.keys(garmentFacets).forEach((facetKey) => {
                    // We don't update the data if we just modified this facet
                    if (
                        !lastFacetModified ||
                        lastFacetModified !== facetKey ||
                        activeFiltersKeys.length === 0 ||
                        (activeFilters[lastFacetModified] === undefined &&
                            lastFacetModified === facetKey)
                    ) {
                        const facetKeyData = [...cloneLocalFilters[garmentType][facetKey].data]
                        facetKeyData.forEach((data) => {
                            data.count =
                                garmentFacets[facetKey].data.find(
                                    (newData) => newData.value === data.value
                                )?.count || 0
                        })

                        cloneLocalFilters[garmentType][facetKey] = {
                            ...cloneLocalFilters[garmentType][facetKey],
                            data: facetKeyData,
                        }
                    }
                })
                setLocalFilters(cloneLocalFilters)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentFacets])

    useEffect(() => {
        if (
            garmentData &&
            currentGarment &&
            garmentData.items.length > 0 &&
            garmentData.items.findIndex(
                (garment) => garment.garment_id === currentGarment.garment_id
            ) === -1
        ) {
            dispatch(
                HandleLookRequest({
                    lookRequest: {
                        ...lookRequest,
                        [garmentData.items[0].garment_type.toLowerCase()]: garmentData.items[0],
                    },
                    focus: garmentData.items[0].garment_type,
                })
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentData])

    useEffect(() => {
        if (company && company.garment_types && company.garment_types.length && garmentType) {
            if (company.garment_types.indexOf(garmentType) === -1) {
                // ---- We check if we have a look already and set the primary type from it ----
                if (lookRequest) {
                    const lookPrimaryTypes = getPrimaryTypesForLook(lookRequest)
                    if (lookPrimaryTypes.length > 0) {
                        dispatch(FetchTypeGarmentAction(lookPrimaryTypes[0].toUpperCase()))

                        return
                    }
                }

                // ---- If not the set the default first garment_type from config ----
                dispatch(FetchTypeGarmentAction(company.garment_types[0]))

                return
            }
        }
        // eslint-disable-next-line
    }, [company])

    const handleTitleClick = (e) => {
        e.preventDefault()
        trackEvent('Home Clicked', {}, 'Menu')
        if (location.pathname !== '/swipe') {
            customHistory.push('/swipe')
        }
    }

    const handleBurgerOpen = (value: boolean) => {
        if (value) {
            trackEvent('Burger Menu Open', {}, 'Menu')
        }
        setOpen(value)
    }

    const handleBackClick = () => {
        trackEvent('Return Clicked', { back_to: customHistory.getBack() }, 'Menu')
        return customHistory.goBack()
    }

    const handleOnFilterUpdate = (value?: string | null, name?: string) => {
        setLastFacetModified(name || '')
    }

    /**
     * Handle the clic on the sub header categories
     * @param facetValue string: the facetValue we just clicked
     * @returns
     */
    const handleSubHeaderClick = (facetValue: string) => {
        const isAll = facetValue === 'ALL'
        // If element is already selected we ignore
        if (
            (onlyOneSubCategory &&
                activeFilters[company.garment_category_facets[garmentType]][0] === facetValue) ||
            (Object.keys(activeFilters).length === 0 && isAll)
        ) {
            return
        }

        trackEvent(
            'Sub Header Clicked',
            { catalog_type: garmentType, catalog_category: facetValue },
            'Menu'
        )

        // We reset anyway the filters if we click on a tab
        dispatch(resetAllFilterForType(garmentType))
        setLastFacetModified(company.garment_category_facets[garmentType])

        getGarmentsTrigger({
            ...(allGarments[garmentType]?.filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: 1,
            filter: isAll ? {} : { [company.garment_category_facets[garmentType]]: [facetValue] },
        })

        if (!isAll) {
            dispatch(
                addFilterForType({
                    type: garmentType,
                    facetKey: company.garment_category_facets[garmentType],
                    facetValue,
                })
            )
        }
    }

    const handleOnScrollLeftContent = async (e: React.UIEvent<HTMLDivElement>) => {
        // Only handle scrolling on catalog page
        if (location.pathname !== '/catalog') {
            return
        }

        const containerHeight = e.currentTarget.clientHeight
        const { scrollHeight } = e.currentTarget

        const { scrollTop } = e.currentTarget
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100

        const lastQueryResult = allGarments[garmentType]?.current

        if (percentageScroll < 70 || isLoading || !lastQueryResult) {
            return
        }

        // Prevent useless call
        if (
            lastQueryResult &&
            allGarments[garmentType].all.length <=
                (currentPage - 1) * lastQueryResult.num_items_per_page
        ) {
            return
        }

        // Prevent loading 1 page that doesn't exist
        if (
            lastQueryResult.current_page_number * lastQueryResult.num_items_per_page >=
            lastQueryResult.total_count
        ) {
            return
        }
        getGarmentsTrigger({
            ...(allGarments[garmentType].filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: currentPage + 1,
        })
    }

    const domain = getQueryValue('domain')
    const withGarmentFilters =
        company.garment_filters === true || getQueryValue('garment_filters') !== null
    const withModelFilters =
        company.model_filters === true || getQueryValue('model_filters') !== null

    const showSubHeader = useMemo<boolean>(
        () =>
            company.garment_category_facets !== null &&
            company.garment_category_facets !== undefined &&
            company.garment_category_facets[garmentType] !== null,
        [company, garmentType]
    )

    return (
        <>
            <Modal
                destroyOnClose
                open={modalVisible && lookRecommendations !== null}
                footer={null}
                centered={window.innerWidth < 768 ? false : true}
                width={window.innerWidth < 768 ? '100%' : '50%'}
                onCancel={() => {
                    dispatch(handleModalAction(false))
                }}
                afterClose={() => {
                    dispatch(FetchRecommendationsAction(null))
                }}
                className='modal modal--model-container'
            >
                <ModalAddedCart />
            </Modal>
            {window.innerWidth < 768 && (
                <Modal
                    destroyOnClose
                    closable={false}
                    open={showFilters}
                    footer={null}
                    centered={false}
                    width={'100%'}
                    onCancel={() => dispatch(ChangeShowFiltersAction(false))}
                    className='modal'
                >
                    <div style={{ width: '100%' }}>
                        <FilterResponsive
                            allFilters={localFilters}
                            isMobile
                            onFilterUpdate={handleOnFilterUpdate}
                        />
                    </div>
                </Modal>
            )}

            <Layout
                className={`layout layout--container layout--page-${location.pathname.substring(
                    1
                )} ${!withGarmentFilters ? 'layout--only-topbottom' : ''}`}
            >
                <Header className='layout--header'>
                    <div className='layout--header-container'>
                        {location.pathname === '/swipe' ? (
                            <ExitButton hideMobile />
                        ) : (
                            <Button
                                onClick={handleBackClick}
                                icon={<ArrowLeftOutlined />}
                                type='link'
                                className='button layout--header-burger-button button--desktop'
                            />
                        )}
                        {/* Main Title */}
                        <div className={`layout--header-title`}>
                            {domain == 'christmas.com' ? (
                                <h1
                                    className={`title title--h1 title--h1-christmas title--main ${
                                        company.garment_types.length > 2 && withGarmentFilters
                                            ? 'title--hide-overflow'
                                            : ''
                                    }`}
                                    onClick={handleTitleClick}
                                >
                                    {'Christmas outfit creator'}
                                </h1>
                            ) : (
                                <h1
                                    className={`title title--h1 title--main ${
                                        company.garment_types.length > 2 && withGarmentFilters
                                            ? 'title--hide-overflow'
                                            : ''
                                    }`}
                                    onClick={handleTitleClick}
                                >
                                    {t('layout.see_the_look')}
                                </h1>
                            )}
                        </div>
                    </div>

                    {/* Main Content */}
                    <LocalFiltersContext.Provider
                        value={{
                            localFilters,
                            onSubHeaderClick: handleSubHeaderClick,
                            onlyOneSubCategory,
                        }}
                    >
                        <HeaderMainContent
                            onBackClick={handleBackClick}
                            toggleDrawer={handleBurgerOpen}
                            showSubHeader={showSubHeader}
                            withGarmentFilters={withGarmentFilters}
                        />
                    </LocalFiltersContext.Provider>
                </Header>

                <div className={`layout--content`}>
                    <div className='layout--left-overlay'>
                        <Content
                            id='layoutScrollableContent'
                            className={`layout--left-content`}
                            onScroll={handleOnScrollLeftContent}
                        >
                            <LocalFiltersContext.Provider
                                value={{
                                    localFilters,
                                    onSubHeaderClick: handleSubHeaderClick,
                                    onlyOneSubCategory,
                                    onFilterClick: (show) => {
                                        trackEvent(
                                            'Filter Clicked',
                                            { catalog_type: garmentType, filter_type: 'item' },
                                            'Menu'
                                        )
                                        dispatch(ChangeShowFiltersAction(show))
                                    },
                                }}
                            >
                                {props?.children}
                            </LocalFiltersContext.Provider>
                            {isLoading && !error && location.pathname === '/catalog' && (
                                <div>
                                    <Loader />
                                </div>
                            )}
                            {error && location.pathname === '/catalog' && (
                                <div>
                                    {t('error.error') + (error as API.ErrorQuery).data.message}
                                </div>
                            )}
                            {location.pathname !== '/swipe' && <PoweredBy mobile />}
                        </Content>

                        {['/swipe', '/catalog'].includes(location.pathname) && (
                            <Col className='layout--toggleview button--desktop'>
                                <ToggleViewButtons />
                            </Col>
                        )}
                    </div>

                    <Content
                        id='layoutRightContentId'
                        className={`layout--right-content`}
                        style={
                            contentActualWidth &&
                            (location.pathname === '/catalog' ||
                                location.pathname === '/favorites' ||
                                location.pathname === '/product' ||
                                location.pathname === '/swipe' ||
                                (location.pathname === '/model' && !withModelFilters))
                                ? { width: contentActualWidth, flex: 'none' }
                                : null
                        }
                    >
                        <div
                            style={{
                                ...size.getLayoutRightContentStyle('minHeight'),
                                height: '100%',
                            }}
                        >
                            {showFilters && window.innerWidth >= 768 ? (
                                <FilterResponsive
                                    allFilters={localFilters}
                                    onFilterUpdate={handleOnFilterUpdate}
                                />
                            ) : (
                                <>
                                    {!showFilters &&
                                        location.pathname === '/model' &&
                                        withModelFilters && <FiltersModel mobile={false} />}
                                    {!showFilters &&
                                        location.pathname !== '/swipe' &&
                                        (location.pathname !== '/model' || !withModelFilters) && (
                                            <>
                                                <LookContainer
                                                    contentMaxWidth={
                                                        contentMaxWidth - 2 * contentMargin
                                                    }
                                                />
                                                <StyleBar
                                                    height={
                                                        window.innerWidth >= 768
                                                            ? STYLEBAR_HEIGHT_DESKTOP
                                                            : STYLEBAR_HEIGHT_MOBILE
                                                    }
                                                />
                                            </>
                                        )}
                                    {((!showFilters && location.pathname !== '/model') ||
                                        !withModelFilters) && <CartPage />}
                                </>
                            )}
                        </div>
                    </Content>

                    <LookTracking />
                </div>
                <Drawer
                    onClose={() => {
                        setOpen(false)
                    }}
                    className='drawer'
                    placement='left'
                    open={open}
                    width={350}
                    closable={false}
                >
                    <DrawerContent onClose={setOpen} />
                </Drawer>
            </Layout>
        </>
    )
}

export default LayoutContainer
