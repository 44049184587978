import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import { HandleLookRequest } from '../../store/actions/look'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart, getGarmentSizeLabel } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'

import FilterSelect from 'src/components/select/filter'
import useCustomHistory from 'src/utils/custom-history-hook'
import { getFavoriteIcon } from 'src/utils/icon'
import { isGarmentInFavorites, isLookInFavorites } from 'src/utils/favorite'
import useShowModal from 'src/utils/showModal'
import ImageSmooth from '../image/ImageSmooth'
import { useAppSelector } from 'src/store'
import { FetchTypeGarmentAction } from 'src/store/actions/garment'
import { resetAllFilters } from 'src/store/slices/databaseSlice'
import { Button, Card, Typography } from 'antd'

const { Title, Paragraph } = Typography

interface CardFavoritesProps {
    data: any
    favorites?: any
    isOutfit: boolean
    addOnFavorites?(value: any): void
    removeFromFavorites?(item: any): void
    seeDetailClick?(): void // Additional function called on detail click
    ratio: number
    noClick?: boolean // Disable click on card
    customImageWidth?: number | string
    eventCategory?: string
}

const CardFavorites: React.FunctionComponent<CardFavoritesProps> = (props) => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const customHistory = useCustomHistory()
    const showModal = useShowModal()
    const {
        data,
        favorites,
        isOutfit,
        addOnFavorites,
        removeFromFavorites,
        ratio,
        noClick,
        seeDetailClick,
        customImageWidth,
        eventCategory = 'Favorite',
    } = props
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const descriptionCatalog = useSelector(
        (state: State.Root) => state.profile?.company?.description_catalog
    )
    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)
    const garmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const [currentSize, setCurrentSize] = useState(null)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const sizeSelectRef = useRef(null)

    const look = useAppSelector((state) => state.look.current)
    const lookRequest = useAppSelector((state) => state.look.request)

    const [clickedGarment, setClickedGarment] = useState<boolean>(false)
    let inFavorites = null
    if (favorites) {
        inFavorites = isOutfit
            ? isLookInFavorites(favorites, data)
            : isGarmentInFavorites(favorites, data)
    }

    const isPromotion = useMemo(() => {
        if (data) {
            return !!data.product_price_original && data.product_price_original > data.product_price
        }

        return false
    }, [data])

    const optionSize = isOutfit ? [] : getGarmentOptionSizes(data)

    const imageUrl = isOutfit
        ? data.image_urls[0]
        : useClipping && data.image_clipping_url
        ? data.image_clipping_url
        : data.image_url

    const handleCardClick = () => {
        if (noClick) {
            return
        }

        dispatch(resetAllFilters())

        if (isOutfit) {
            trackEvent('Outfit Clicked', data, eventCategory)
            dispatch(HandleLookRequest({ lookRequest: data }))
            let setGarment = data.top || data.bottom
            if (!setGarment) {
                for (let i = 0; i <= garmentTypes.length; i++) {
                    const dataGarment = data[garmentTypes[i].toLowerCase()]
                    if (dataGarment) {
                        setGarment = dataGarment

                        break
                    }
                }
            }
            dispatch(FetchTypeGarmentAction(setGarment.garment_type))
        } else {
            trackEvent('Item Clicked', data, eventCategory)
            dispatch(
                HandleLookRequest({
                    lookRequest: {
                        ...lookRequest,
                        [data.garment_type.toLowerCase()]: data,
                    },
                    focus: data.garment_type,
                })
            )
            dispatch(FetchTypeGarmentAction(data.garment_type))
        }

        if (window.innerWidth < 768) {
            setClickedGarment(true)
        }
        document.getElementById('layoutRightContentId').scrollTo({ top: 1, behavior: 'smooth' })
    }

    const handleSizeChange = (value) => {
        if (value) {
            trackEvent(
                'Size Selected',
                [
                    data,
                    {
                        item_size_selected: value,
                        item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                    },
                ],
                eventCategory
            )
        }
        setCurrentSize(value)
        setGoToCart(false)
    }

    const handleAddToCart = (e) => {
        e.stopPropagation()
        if (!currentSize) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                data,
                {
                    item_size_selected: currentSize,
                    item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                },
            ],
            eventCategory
        )
        setAddingToCart(true)
        addGarmentSizeToCart(data, currentSize, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        data,
                        {
                            item_size_selected: currentSize,
                            item_size_selected_label: getGarmentSizeLabel(currentSize, optionSize),
                        },
                    ],
                    eventCategory
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                showModal(data.garment_id, data, eventCategory)
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [data], eventCategory)
    }

    const handleSeeGarmentDetail = (e) => {
        e.stopPropagation()
        trackEvent('Item Detailed', data, eventCategory)
        dispatch(
            HandleLookRequest({
                lookRequest: { [data.garment_type.toLowerCase()]: data },
                focus: data.garment_type,
            })
        )
        dispatch(FetchTypeGarmentAction(data.garment_type))
        customHistory.push('/product')
        if (seeDetailClick) {
            seeDetailClick()
        }
        document.getElementById('layoutRightContentId').scrollTo({ top: 1, behavior: 'smooth' })
    }

    const handleSeeLookDetail = (e) => {
        e.stopPropagation()
        trackEvent('Outfit Detailed', data, eventCategory)
        dispatch(HandleLookRequest({ lookRequest: data }))

        if (window.innerWidth < 768) {
            return customHistory.push('/swipe', { toDetail: true })
        }

        const cartElem = document.getElementById('cartContainerId')
        return cartElem.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        if (isOutfit) {
            trackEvent('Outfit Saved to favorite', data, eventCategory)
        } else {
            trackEvent('Item Saved to favorite', data, eventCategory)
        }
        addOnFavorites(data)
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        if (isOutfit) {
            trackEvent('Outfit Removed from favorite', data, eventCategory)
        } else {
            trackEvent('Item Removed from favorite', data, eventCategory)
        }
        removeFromFavorites(data)
    }

    // We need to wait that the look is updated with the data before navigating in mobile
    useEffect(() => {
        if (clickedGarment) {
            if (isOutfit) {
                // If data is a look we compare the look_id
                if (look.look_id === data.look_id) {
                    setClickedGarment(false)
                    return customHistory.push('/swipe')
                }
            } else {
                // If data is a garment we compare de garment_id for the correct garment_type
                if (look[data.garment_type.toLowerCase()]?.garment_id === data.garment_id) {
                    setClickedGarment(false)
                    return customHistory.push('/swipe')
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [look])

    return (
        <Card
            className={
                'card card--container card--container-favorite override_card_container override_card_catalog_container' +
                (!isOutfit && data.product_brand ? ' card--with-brand' : '') +
                (!isOutfit && descriptionCatalog === false ? ' card--without-description' : '')
            }
            hoverable={!noClick}
            onClick={handleCardClick}
            cover={
                <div className='card card--image-container'>
                    <div
                        className='card--image override_card_cover_catalog'
                        style={customImageWidth && { width: customImageWidth }}
                    >
                        <ImageSmooth
                            className='card--background override_img_container'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                            cover={isOutfit}
                        />
                    </div>
                    {!isOutfit && (
                        <div className='button--underlined' onClick={handleSeeGarmentDetail}>
                            {t('product.see_detail')}
                        </div>
                    )}
                </div>
            }
        >
            {!isOutfit ? (
                <>
                    {data.product_brand && (
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                            }}
                            className='text text--center text--small card--text override_card_brand'
                        >
                            {parse(data.product_brand)}
                        </Paragraph>
                    )}
                    <Title
                        ellipsis={{
                            rows: descriptionCatalog === false ? 2 : 1,
                        }}
                        className='title title--h3 title--center card--title override_card_title'
                    >
                        {parse(data.product_name)}
                    </Title>
                    {descriptionCatalog !== false && (
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                            }}
                            className='text text--center text--small card--text override_card_description'
                        >
                            {parse(data.product_description)}
                        </Paragraph>
                    )}
                    {isPromotion && (
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title title--center card--price--promotion'
                        >
                            <span className='card--price--original'>
                                {formattedPrice(
                                    data.product_price_original,
                                    data.product_currency,
                                    priceFloat
                                )}
                            </span>
                            <span className='card--price--percent'>
                                -
                                {Math.round(
                                    ((data.product_price_original - data.product_price) * 100) /
                                        data.product_price_original
                                )}
                                %
                            </span>
                        </Title>
                    )}
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className={`title title--center title--h2 card--title card--price--final${
                            isPromotion ? ' card--price--final--promotion' : ''
                        }`}
                    >
                        {formattedPrice(data.product_price, data.product_currency, priceFloat)}
                    </Title>
                    <div className='card card--favorites-options'>
                        <FilterSelect
                            ref={sizeSelectRef}
                            name='size'
                            onChange={handleSizeChange}
                            placeholder={t('product.size')}
                            translation={false}
                            value={currentSize}
                            options={optionSize}
                        />
                        {goToCart && cartUrl ? (
                            <Button
                                className='button card--main'
                                onClick={handleGoToCart}
                                href={cartUrl}
                                target={company.link_target_parent ? '_parent' : '_blank'}
                                type='primary'
                                key='goToCart'
                            >
                                {t('product.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button card--main'
                                loading={addingToCart}
                                onClick={handleAddToCart}
                                type='primary'
                            >
                                {t('product.add_to_cart')}
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div
                    className='button--underlined favorites--look-detail'
                    onClick={handleSeeLookDetail}
                >
                    {t('look.see_detail')}
                </div>
            )}
            {addOnFavorites &&
                removeFromFavorites &&
                (!inFavorites ? (
                    <Button
                        onClick={handleAddToFavorite}
                        icon={getFavoriteIcon('outlined')}
                        type='link'
                        className='button card--favorite'
                    ></Button>
                ) : (
                    <Button
                        icon={getFavoriteIcon('filled')}
                        type='link'
                        className='button card--favorite'
                        onClick={handleRemoveFromFavorite}
                    ></Button>
                ))}
        </Card>
    )
}

export default CardFavorites
