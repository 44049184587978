import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export default function useCustomSize() {
    const location = useLocation()
    const enableHideHeader = useSelector(
        (state: State.Root) => state.profile?.company?.enable_hide_header
    )
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)

    const showSubHeader = useMemo(
        () =>
            company.garment_category_facets &&
            company.garment_category_facets[garmentType] &&
            location.pathname !== '/model' &&
            location.pathname !== '/favorites' &&
            location.pathname !== '/product',
        [company, garmentType, location]
    )

    function getLayoutScrollableContentStyle(field = 'height') {
        return enableHideHeader && parentHeight
            ? {
                  [field]:
                      Number(parentHeight) +
                      10 -
                      (Number(window.innerWidth) >= 768
                          ? 40 +
                            (showSubHeader ? 35 : 0) +
                            // padding top is NOT in the model page
                            (location.pathname !== '/model' ? 16 : 0)
                          : // On model case: 48 -> 1 header line, 36 -> powered by without margin
                          location.pathname === '/model'
                          ? 34 + (showSubHeader ? 35 : 0) + 36
                          : 34 + (showSubHeader ? 35 : 0) + 16),
              }
            : {
                  // Nothing
              }
    }

    function getLayoutRightContentStyle(field = 'height') {
        return enableHideHeader && parentHeight
            ? {
                  [field]: Number(parentHeight) - 62 - 16,
              }
            : {
                  // Nothing
              }
    }

    return { getLayoutScrollableContentStyle, getLayoutRightContentStyle }
}
